.groupsContent {
  padding: 24px;
  background-color: #f0f2f5;
  padding: 24px;
  background-color: #f0f2f5;
}

.optionMenu {
  padding: 8px !important;
  cursor: pointer !important;
  font-size: 13px !important;
  color: #5e6c84 !important;
  &:hover {
    color: #7043ff !important;
    background: none !important;
  }
  padding: 8px !important;
  cursor: pointer !important;
  font-size: 13px !important;
  color: #5e6c84 !important;
  &:hover {
    color: #7043ff !important;
    background: none !important;
  }
}
.groupCard {
  background-color: #ffffff;
  border-radius: 8px;
  flex: 1 1 32%; /* Allows items to grow and shrink, setting a base width of 30% */
  max-width: 32%;
  cursor: unset !important;
  .cardImage {
    background: #ebecf0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }

  .cardHeader {
    margin-bottom: 12px;
    .groupMenu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      overflow: hidden;
      white-space: nowrap;
    }
    .groupButton {
      display: flex;
      width: 50px;
      justify-content: space-between;
    }
    .groupName {
      text-overflow: ellipsis;
      font-size: 14px;
      color: #5e6c84;
      font-weight: 600;
    }
  }

  .leads {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 12px;
  }

  .numberOfLeads {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;

    span {
      text-overflow: ellipsis;
    }

    img {
      width: 19px;
      margin-right: 4px;
    }
  }

  .groupStatus {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;

    span {
      text-overflow: ellipsis;
      padding-top: 2px;
    }

    img {
      height: 16px;
      margin-right: 4px;
    }
  }

  .link {
    display: flex;
    align-items: center;
    color: #5e6c84;
    &:hover {
      text-decoration: underline;
    }

    img {
      height: 16px;
      margin-right: 4px;
    }
  }

  .platform {
    display: flex;
    align-items: center;
    color: #5e6c84;
    font-weight: 400;
    img {
      height: 16px;
      margin-right: 4px;
    }
  }

  .cardBody {
    .groupDescription {
      color: #091e42;
      font-size: 12px;
      padding-bottom: 12px;
      min-height: 42px;
      max-height: 42px;
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .groupTags {
      .tag {
        display: inline-block;
        background-color: #f2f0ff;
        color: #7043ff;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }
}
@media screen and (min-width: 1570px) {
  .groupCard {
    background-color: #ffffff;
    border-radius: 8px;
    flex: 1 1 24%; /* Allows items to grow and shrink, setting a base width of 30% */
    max-width: 24%;
    cursor: unset !important;
    .cardImage {
      background: #ebecf0;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }
    }

    .cardHeader {
      margin-bottom: 12px;
      .groupMenu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        overflow: hidden;
        white-space: nowrap;
      }
      .groupButton {
        display: flex;
        width: 50px;
        justify-content: space-between;
      }
      .groupName {
        text-overflow: ellipsis;
        font-size: 14px;
        color: #5e6c84;
        font-weight: 600;
      }
    }

    .leads {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: normal;
      margin-bottom: 12px;
    }

    .numberOfLeads {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;

      span {
        text-overflow: ellipsis;
      }

      img {
        width: 19px;
        margin-right: 4px;
      }
    }

    .groupStatus {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;

      span {
        text-overflow: ellipsis;
        padding-top: 2px;
      }

      img {
        height: 16px;
        margin-right: 4px;
      }
    }

    .link {
      display: flex;
      align-items: center;
      color: #5e6c84;
      &:hover {
        text-decoration: underline;
      }

      img {
        height: 16px;
        margin-right: 4px;
      }
    }

    .platform {
      display: flex;
      align-items: center;
      color: #5e6c84;
      font-weight: 400;
      img {
        height: 16px;
        margin-right: 4px;
      }
    }

    .cardBody {
      .groupDescription {
        color: #091e42;
        font-size: 12px;
        padding-bottom: 12px;
        min-height: 42px;
        max-height: 42px;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .groupTags {
        .tag {
          display: inline-block;
          background-color: #f2f0ff;
          color: #7043ff;
          border-radius: 8px;
          padding: 2px 8px;
          font-size: 12px;
          margin-right: 8px;
        }
      }
    }
  }
}
.noTags {
  margin-bottom: 16px;
  color: #97a0af;
  font-size: 12px;
  padding-bottom: 12px;
  min-height: 50px;
  max-height: 50px;
  margin-bottom: 16px;
  color: #97a0af;
  font-size: 12px;
  padding-bottom: 12px;
  min-height: 50px;
  max-height: 50px;
}

.progress {
  padding: 4px 0px;
  padding: 4px 0px;
}

.disabled {
  cursor: not-allowed;
  filter: grayscale(10%);
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(10%);
  opacity: 0.6;
  pointer-events: none;
}

@media (max-width: 1190px) {
  .groupCard {
    max-width: 50%;
  }
}
