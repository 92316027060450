.customMarks {
  //   position: relative;
  height: 20px;
  margin-top: -10px;
  width: calc(100% + 10px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //   padding-block: 4px;
  //   margin: 11px 5px;
  .mark {
    // position: absolute;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    transform: translateX(-20%);
    width: 20px;
    text-align: center;
    cursor: pointer;
    color: #888;

    span {
      line-height: normal;
      font-size: 12px;
    }
  }

  .activeMark {
    color: #7c3aed;
    font-weight: bold;
  }
}
