.gaugeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 33%;
  height: auto;
}

.gauge {
  width: 70%;
  height: auto;
  //   transform: scale(0.9, 0.8);
}

.label {
  font-size: 13px;
  font-weight: bold;
  margin-top: 8px;
}

.percentage {
  margin-top: 9px;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
}
