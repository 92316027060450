.top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  button {
    border: 0;
    box-shadow: none;
    color: #0575b3;
    span {
      text-decoration: underline;
    }
  }
}

p {
  margin-top: 5px;
}
.view_all_channel_container {
  background: linear-gradient(to top, #b649f3, #7144ff);
  background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
  padding: 1px;
  border-radius: 10px;
  margin: 20px 0;

  .view_all_channel {
    border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background: #faf9ff;
    border-radius: 10px;
    div {
      display: flex;
      align-items: center;
    }
    p {
      margin: 0 0 0 10px;
    }
  }
}
.chat_channels {
  .left {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    img {
      max-height: 24px;
      margin-right: 5px;
    }
    .count {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px 4px;
      background: #7043ff;
      border-radius: 66.6667px;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      line-height: 11px;
    }
  }
  .chat_channels_accounts {
    display: flex;
    align-items: center;
    border: 1px solid #dfe1e6;
    border-radius: 24px;
    padding: 4px 10px;
    .chat_channels_dot {
      display: inline-block;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #ffc700;
    }
    p {
      margin: 0;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.ChatTabsSidebar {
  display: flex;
  height: 100%;
  .ChatTabsSidebarContent {
    padding: 15px;
    height: 100%;
    width: 519px;
    background-color: #f9fafb;
    border-right: 1px solid #dfe1e6;
    overflow-x: auto;
    h1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      margin: 0 0 10px;
    }
    h2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin: 0 0 20px;
    }
  }
}
.optionsTiles {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
  .optionsTiles__Content {
    width: 29%;
    border-radius: 10px;
    border: 1px solid #dfe1e6;
    padding: 10px 5px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      border: 1px solid #7043ff;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: #091e42;
      text-align: center;
      margin-top: 0;
      margin-bottom: 3px;
    }
    h1 {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #111111;
      margin: 0;
      text-align: center;
    }
    &.activeboxOptionsTiles {
      color: red;
    }
  }
}
.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;

  &__input {
    position: relative;
    input {
      background: #ffffff no-repeat;
      background-position: left 20px bottom 10px;
      padding-left: 50px;
      border: 1px solid transparent;
      border-radius: 10px;
    }

    &__result {
      position: absolute;
      top: 50px;
      display: flex;
      padding: 16px;

      display: flex;
      // width: 210px;
      width: fit-content;
      z-index: 99;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      border-bottom: 1px solid var(--Nutrals-800, #f3f4f6);
      background: #fff;
      box-shadow: 0px 10px 90px 0px rgba(0, 0, 0, 0.15);

      &__title {
        color: #97a0af;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__list {
        margin-top: 12px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        max-height: 250px;
        overflow-y: auto;
      }
    }
  }

  input {
    background: #ffffff no-repeat;
    background-position: left 20px bottom 10px;
    padding-left: 50px;
    border: 1px solid transparent;
    border-radius: 10px;
  }
  .search_select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    border: 1px solid #d9d9d9;
    padding: 0 11px;
    border-radius: 6px;
    background-color: #ffffff;
    .prefix_text {
      text-wrap: nowrap;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.001em;
      color: #97a0af;
    }
  }
}
.chat_box_title {
  // margin: 20px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #051123;
  display: flex;
  align-items: center;
}
.chat_receipt_box {
  background: #ffffff;
  border: 1px solid #dfe1e6;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;

  .redDots {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #ff0000;
    border-radius: 50%;
    top: 8px;
    left: 10px;
  }
  .chat_receipt_box__topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      span {
        margin-right: 8px;
      }
    }
    .chat_receipt_box__topSection__right {
      .chat_receipt_box__topSection__time {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #97a0af;
      }
    }
  }
  .chat_receipt_box__middleSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: #091e42;
    }
    .chat_receipt_box__middleSection__icons {
      span {
        margin-left: 8px;
        cursor: pointer;
        svg {
          color: #97a0af;
        }
      }
    }
  }
}

.ChatTabsMessageContent {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.NoChat {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .NoChat__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      max-width: 240px;
      margin: 0 auto;
    }
    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: #000000;
      margin: 20px 0 10px;
      text-align: center;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #091e42;
      margin: 0;
      text-align: center;
    }
  }
}

.MessageComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  .MessageComponent__TopSection {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #dfe1e6;
    padding: 16px 24px;
    line-height: normal;
    .MessageComponent__TopSection__left {
      display: flex;
      align-items: center;
      .left_recipient {
        display: flex;
        flex-direction: column;
        h2 {
          margin: 0;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          color: #000000;
        }
        .MessageComponent__TopSection__left__tags {
          display: flex;
          align-items: center;
          > * {
            line-height: normal;
          }
          .socials {
            padding-left: 8px;
            border-left: 1px solid #ebecf0;
            img {
              width: 20px;
              border-radius: 50%;
              margin-right: 2px;
            }
          }
          .tags {
            span {
              padding: 4px 12px;
              background: #f2f0ff;
              border-radius: 8px;
              border: 0;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.004em;
              color: #7043ff;
              margin-top: 5px;
            }
            .more {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.004em;
              color: #7043ff;
              margin-right: 8px;
            }
          }
        }
      }
      .avatar {
        max-width: 56px;
        border-radius: 50%;
        margin-right: 15px;
      }
    }
    .MessageComponent__TopSection__right {
      min-width: 185px;
      display: flex;
      align-items: center;
      .pin {
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 12px;
        margin-right: 12px;
        border-left: 1px solid #ebecf0;
        border-right: 1px solid #ebecf0;
        img {
          cursor: pointer;
        }
        svg {
          cursor: pointer;
          color: #97a0af;
        }
      }
      .enrich {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.002em;
        color: #7043ff;
        cursor: pointer;
      }
    }
  }
  .MessageComponent__bottomSection {
    padding: 24px;
    border-top: 1px solid #dfe1e6;
    line-height: normal;
    display: flex;
    justify-content: space-between;
  }
  .MessageComponent__middleSection {
    height: 100%;
    overflow-x: auto;
    line-height: normal;
    padding: 16px 24px;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    .left_chat {
      display: flex;
      align-items: flex-start;
      margin: 20px 0;
      .avatar {
        width: 32px;
        border-radius: 50%;
        margin-right: 12px;
      }
      p {
        padding: 8px 12px;
        background: #f9fafb;
        border: 1px solid #dfe1e6;
        border-radius: 0px 10px 10px 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #091e42;
        margin: 0;
        max-width: 298px;
        margin-bottom: 12px;
      }
      .left_chat_time {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #97a0af;
        .left_chat_text__dots {
          width: 4px;
          height: 4px;
          background: #97a0af;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin: 0 5px;
        }
      }
    }
    .right_chat {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0;

      .right_chat_text {
        max-width: 338px;
        p {
          display: flex;
          flex-direction: row;
          padding: 8px 12px;
          background: linear-gradient(107.68deg, #7144ff 14.56%, #b649f3 125.16%);
          border-width: 1px 1px 0px 1px;
          border-style: solid;
          border-color: #e8eef3;
          box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.03);
          border-radius: 10px 0px 10px 10px;
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #ffffff;
          margin-bottom: 12px;
        }
        .left_chat_time {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #97a0af;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          img {
            cursor: pointer;
          }
          .left_chat_text__dots {
            width: 4px;
            height: 4px;
            background: #97a0af;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
          }
        }
      }
    }
  }
}
.MessageComponent_sider {
  .MessageComponent_sider__topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    h1 {
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.0015em;
      color: #051123;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    svg {
      cursor: pointer;
    }
  }
  .sider_bookmark {
    padding: 8px 12px;
    background: #f9fafb;
    border: 1px solid #dfe1e6;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #091e42;
    margin-top: 24px;
    &:hover {
      background: #ffffff;
      border: 1px solid #7144ff;
    }
  }
}
.view_all_channel_item {
  .chat_channels_accounts {
    border: 0;
    padding: 0;
  }
}

.reactVirtualizer {
  width: calc(100% + 7px);
  padding-right: 7px;
}
.reactVirtualizer::-webkit-scrollbar {
  width: 2px;
}

.pinedChats {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  img {
    margin-left: 5px;
  }
  h3 {
    margin: 0px;
  }
}

.outerDiv,
.outerDiv * {
  overflow-anchor: none !important;
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagHeader {
  display: flex;
  justify-content: space-between;
}
