.container {
  position: relative;
  .topSection {
    Button {
      background-color: #f3f4f6;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      margin: 0px;
      span {
        font-size: 13px;
        font-weight: 600;
      }
      img {
        margin-bottom: 2px;
      }
    }
    Button:hover {
      opacity: 0.8;
    }
  }
}

.draggableItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  //   justify-content: space-between;

  width: 100%;

  margin-bottom: 10px;

  .handle {
    cursor: grab;
    margin-right: 8px;
  }

  .innerBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 8px;
    background-color: white;
    border-radius: 8px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: #5e6c84;
}
