.container {
  background-color: #f3f4f6;
  height: 100vh; /* Ensure the container takes full viewport height */
  display: flex;
  flex-direction: column; /* Stack header and content */
}

.header {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  height: 91px;
  gap: 5px;
  border-bottom: 1px solid #dfe1e6;

  p {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  span {
    font-size: 12px;
    margin: 0;
  }
}

.content {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-grow: 1;
  overflow: auto;
}

.leftSide {
  flex: 0.6;
  display: flex;
  flex-direction: column;

}

.rightSide {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  gap: 3%;

}

.cardHeader {
  padding: 16px;
  height: 55px;
  border-bottom: 1px solid #dfe1e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
}

.availablePoints {
  border: 1px solid #dfe1e6;
  border-radius: 20px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  span {
    color: var(--Natural-700, #5e6c84);
    text-align: justify;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dot {
    height: 4px;
    width: 4px;
    background-color: #50e2ba;
    border-radius: 50%;
    display: inline-block;
  }
}

.cardBody {
  .section {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #dfe1e6;
    gap: 20px;
    p {
      margin: 0px;
      font-size: 14px;
      font-weight: 600;
    }
    .subSection {
      span {
        color: #97a0af;
      }
    }
    Button {
      margin: 0px;
      border-color: #dfe1e6;
      width: 124px;
      height: 34px;
      font-size: 13px;
    }
  }
}

.cancelPlan {
  margin-top: 50px;
  span {
    cursor: pointer;
    color: #5e6c84;
  }
}

.currentPlan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 64px;
  gap: 16px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 0px;
      font-weight: 600;
      font-size: 26px;
    }
    img {
      height: 80px;
      width: 80px;
    }
  }

  .progress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    p,
    span {
      margin: 0;
    }
    p {
      font-weight: 600;
    }

    .progressInfo {
      color: #5e6c84;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 2px;
    }
  }

  .bottom {
    display: flex;
    gap: 20px;
    Button {
      margin: 0;
      height: 36px;
    }
  }
}

.talkToUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 55px);
  gap: 15px;
  img {
    height: 80px;
    width: 80px;
  }

  .talkDescription {
    color: #97a0af;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-height: 900px) {
  .talkToUs img {
    height: 60px;
    width: 60px;
  }
  .currentPlan .title img {
    height: 60px;
    width: 60px;
  }
}

.fetching {
  font-weight: bold;
  display: inline-block;
  color: #97a0af;
  clip-path: inset(0 3ch 0 0);
  animation: l 1.5s steps(3) infinite;
  margin-left: 2px;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
