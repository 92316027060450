.input {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  align-items: center;
  padding-right: 10px;
  Button {
    margin-left: 20px;
  }
}

.rowStyle {
  width: calc(100% + 10px);
  overflow-y: auto;
  padding-right: 10px;
  height: 100%;
  // overflow: hidden;
  // max-height: 613px;
  // min-height: 613px;
  align-items: flex-start;
  align-content: flex-start;
}

.colStyle {
  // flex: 1 1 auto;
}

.category {
  span {
    color: black;
  }
}

.platforms {
  display: flex;
  margin-bottom: 18px;
}

.categoryHeader {
  color: black;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  .leftSide {
  }

  .rightSide {
    color: #7043ff;
    cursor: pointer;
    padding: 5px;
    border-radius: 12px;
    &:hover {
      background-color: #f3f4f6;
    }
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 100%;
  color: black;
  text-align: center;
  gap: 10px;
  p {
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    line-height: normal;
  }
}
.not {
  // margin-bottom: 50px;
  position: absolute;
  color: var(--Nutrals-200, #091e42);
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 52.083% */
}
.notBox {
  width: 75%;
  display: flex;
  justify-self: center;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
}
.notInner {
  width: 65%;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.notTextFrame {
  display: flex;
  width: 100%;
  height: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}
.collect {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  align-self: stretch;
}
.paraCollect {
  color: #091e42;
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 145.455% */
  align-self: stretch;
}
.collectButtonNot {
  display: flex;
  width: 236px;
  height: 55px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: #7043ff;
  color: var(--Nutrals-1000, #fff);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 90.909% */
  letter-spacing: 0.044px;
}
.mainExploreTitle {
  color: var(--Natural-700, #5e6c84);
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.027px;
}
.mainExploreSeeMore {
  color: var(--primary-600-main, #7043ff);

  /* Label/Label large */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  padding-top: 5px;
  line-height: 30px; /* 142.857% */
  letter-spacing: 0.028px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    transition: opacity 0.5s;
    opacity: 0;
  }
  &:hover {
    img {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
}
