.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
  font-family: 'FbSpoilerPasEng' !important;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: 'FbSpoilerPasEng' !important;
  p {
    font-family: 'FbSpoilerPasEng' !important;
    font-size: 24px;
    margin: 0;
  }

  span {
    font-size: 14x;
    font-family: 'FbSpoilerPasEng' !important;
    font-weight: normal !important;
  }

  h1 {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
    font-family: 'FbSpoilerPasEng' !important;
    line-height: 1;
    padding-top: 10px;
    span {
      font-family: 'FbSpoilerPasEng' !important;
      font-size: 16px;
      margin-left: 5px;
      font-weight: 400;
    }
  }
}

.selector {
  // font-family: 'FbSpoilerPasEng' !important;
  // font-family: 'FbSpoilerPasEng', sans-serif !important;
  background-color: #f2f0ff;
  border-radius: 8px;
  margin: 0px -20px;
  padding: 20px 0px;
  letter-spacing: -0.42px;

  .uniqueLeads {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400 !important;
    margin: 0px 22px;
    color: black;
    span {
      font-family: 'Roboto', sans-serif !important;

      margin-bottom: 20px !important;
    }
  }
  .select {
    // font-family: 'FbSpoilerPasEng' !important;
    margin: 3px 20px;
    // font-family: 'FbSpoilerPasEng', sans-serif !important;
  }

  .optionText {
    font-size: 12.5px;
    color: black;
    span {
      font-family: 'Roboto', sans-serif !important;
    }
    // font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
  .grayText {
    font-family: 'FbSpoilerPasEng' !important;
    font-weight: 400;
    // font-family: 'FbSpoilerPasEng', sans-serif !important;
    color: gray;
  }
}

.upgradeButton {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  background: linear-gradient(to right, #6a45f6, #b84bf8);
  text-align: center;
  color: white;
  font-weight: 600;
  padding: 10px 0px;
  border-radius: 8px;
  cursor: pointer;
  // margin-bottom: 10px;
  z-index: 2;
  &:hover {
    opacity: 0.8;
  }
}

.trial {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  z-index: 1000;
  span {
    font-weight: lighter;
    color: #7043ff;
    cursor: pointer;
  }

  // span:hover {
  //   opacity: 0.7;
  // }
}
.trialSections {
  display: flex;
  flex-direction: column;
}

.activePlan {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  background: linear-gradient(to right, #aaaaaa, #9b9b9b);
  cursor: not-allowed;
}

.buttonText {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.loadingButton {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  cursor: not-allowed;
  opacity: 0.6;
  transform: translateX(10px);
  margin-right: 10px;
}

.features {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  font-family: 'FbSpoilerPasEng', sans-serif !important;

  .feature,
  .flexDiv {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    img {
      margin-right: 10px;
      margin-bottom: 1px;
    }

    span {
      color: #000;
      font-size: 15px;
      letter-spacing: -0.42px;
      font-family: 'FbSpoilerPasEng', sans-serif !important;
      line-height: 0px;
      font-weight: 300 !important;
    }
  }
}

.mostPopular {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  position: absolute;
  top: 5px;
  right: 0px;
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
    background: linear-gradient(to right, #6a45f6, #b84bf8);
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 0px 8px 0px 8px;
    padding: 10px 15px;
  }
}

.whatsIncluded {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #000000;
  border-bottom-style: dashed;
  padding-top: 4px;
  line-height: 1;
  font-size: 12px;
  &:hover {
    opacity: 0.8;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom-style: dashed;
  }
}
.whatsIncluded14 {
  z-index: 1000;
  border-bottom: 1px solid #000000;
  border-bottom-style: dashed;
  font-weight: lighter;
  font-size: 14px;
  line-height: 1.6;
  width: 150px;
  display: inline;
  &:hover {
    opacity: 0.8;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom-style: dashed;
  }
}
span.cancelAnytime {
  font-weight: 300 !important;
}
