.container {
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.form {
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-input {
    height: 40px;
    padding: 8px;
  }

  .ant-btn {
    width: 100%;
    height: 40px;
    font-size: 16px;
  }

  .ant-input-textarea {
    resize: none;
  }
}

.profileCard {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;

  .profileInputsCard {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;

    .inputRow {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .inputContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .inputTitle {
          font-size: 14px;
          color: #777;
        }
      }
    }
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
