.campaignSummarySidebar {
  display: flex;
  height: 100%;

  .campaignSummarySidebarFilter {
    padding: 24px;
    height: 100%;
    width: 423px;
    border-right: 1px solid #ebecf0;
    background-color: #ffffff;
    box-sizing: border-box;
    animation: slideIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    z-index: 0;
    overflow-y: auto;

    .top_section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 24px;
      margin-bottom: 24px;

      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0015em;
        color: #051123;
        margin: 0;
      }

      button {
        border: 0;
        padding: 0;
        box-shadow: none;
      }
    }
  }
}

.CampaignSummaryContent {
  background-color: #ffffff;
  padding: 24px;
  width: 100%;
}

.summaryPrompt {
  border: 1px solid #dfe1e6;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  font-size: 20px;
  background-color: white;
  margin-bottom: 24px;

  h1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    margin: 0;

    &.summaryPromptTitle {
      background: linear-gradient(to right, #7144ff, #b649f3);
      background: -webkit-linear-gradient(to right, #7144ff, #b649f3);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.summaryboxTiles {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 24px;

  .summaryboxTiles__Content {
    width: 29%;
    border-radius: 10px;
    border: 1px solid #dfe1e6;
    padding: 16px 5px;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: #091e42;
      text-align: center;
      margin-top: 0;
      margin-bottom: 6px;
    }

    h1 {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #111111;
      margin: 0;
      text-align: center;
    }
  }
}

.summaryboxTiles__Two {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 24px;
  margin-bottom: 24px;

  .summaryboxTiles__Two__Content {
    width: 46%;
    border-radius: 10px;
    border: 1px solid #dfe1e6;
    padding: 16px 5px;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: #091e42;
      text-align: center;
      margin-top: 0;
      margin-bottom: 6px;
    }

    h1 {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #111111;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 24px;
        margin-right: 5px;
      }
    }
  }
}

.socialMediaContainer {
  margin-bottom: 24px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 24px;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-bottom: 5px;

      p {
        margin: 0;
        color: #091e42;
        font-weight: 400;
        font-size: 14px;
      }

      img {
        margin-right: 5px;
        height: 22px;
      }
    }

    .addAccount {
      label {
        color: #7043ff;
        font-weight: 600;
        cursor: pointer;

        &:after {
          content: url(../../../assets/svg/chevron-bottom.svg);
          margin-left: 10px;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .select_items {
    width: 48%;
    padding: 6px 12px;
    background: #f2f0ff;
    border-radius: 24px;
    text-align: center;
    margin-top: 16px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    .select_items__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.002em;
      color: #4722bc;
      text-overflow: clip;
    }
  }
}

.tooltipContent {
  display: flex;
  flex-wrap: wrap;
  width: 360px;
}

.select_items_tooltip {
  width: 100px;
  padding: 6px 12px;
  background: #f2f0ff;
  border-radius: 24px;
  text-align: center;
  margin: 8px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  .select_items__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    color: #4722bc;
    text-overflow: clip;
  }
}

.select_items__remove {
  position: absolute;
  top: 3px;
  right: 10px;
  display: none;
  cursor: pointer;
}

.select_items:hover .select_items__remove {
  display: inline;
  color: #7043ff;
  font-size: 20px;
  cursor: pointer;
}

.select_items_tooltip:hover .select_items__remove {
  display: inline;
  color: #7043ff;
  font-size: 20px;
  cursor: pointer;
}

.ellipsis {
  width: 50px; /* Set a fixed width */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide the overflowed content */
  text-overflow: ellipsis; /* Show ellipsis (...) when text overflows */
}

.scheduling {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 24px;
  margin-bottom: 24px;
  gap: 10px;
  .schedulingHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    p {
      font-weight: 600;
      margin: 0;
    }
  }
  .schedulingMain {
    //
  }
  .schedulingFooter {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    .save {
      color: #7043ff;
      font-weight: 600;
      cursor: pointer;
    }
    .cancel {
      cursor: pointer;
    }
  }
}
