.menuContainer {
  position: absolute;
  top: 16px;
  right: 0px;
  left: 0px;
  margin: auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 50px;
  border-radius: 50px;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  gap: 14px;
  z-index: 10000;
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

.icon {
  background-color: white;
  border: 1px solid #d1d1d1;
  padding: 6px;
  border-radius: 6px;
  width: 43px;
  height: 43px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.label {
  font-size: 12px;
  color: #333;
  margin-top: 4px;
}

.closeButton {
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: fit-content;
  right: 20px;
  img {
    width: 10px;
    height: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.65;
    }
  }
}

.dropDownContent {
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
}
