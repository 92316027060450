.c-topbar {
  height: var(--topbar-height);
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 32px;

  background-color: var(--color-white);

  box-sizing: border-box;
  border-bottom: 1px solid var(--color-gray-03);

  &-title {
    font-weight: 700;
    font-size: 24px;
    margin: auto 0;
    width: 220px;
  }

  &-button {
    margin: auto 0;
  }

  &-filter {
    width: 450px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: 10px;

    input {
      margin: 0px 10px;
    }

    .button {
      border-radius: 8px;
      border: 1px solid var(--primary-900, #c8bcff);
      background: #fff;
      color: var(--primary-600-main, #7043ff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.014px;
    }
    .button:hover {
      color: var(--primary-600-main, #7043ff) !important;
      border: 1px solid var(--primary-900, #c8bcff) !important;
    }
  }
}

.menu_item_parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 250px;
  height: 40px;
  line-height: 40px;

  &-red {
    background-color: red;
    display: none;
  }
  &-flex-grow {
    flex-grow: 1;
  }
}

.active {
  // background-color: red;
  display: none;
}

.popover_parent_edit_delete {
  width: 126px;
  height: 120px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &-item_edit {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
  }

  &-item_delete {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

.savedFiltersDropdown .ant-dropdown-menu-item-active,
.savedFiltersDropdown .ant-dropdown-menu-item:hover {
  // background: var(--color-blue-04) !important;
  background: var(--item-hover-background) !important;
}

.menu_item_parent-dot {
  visibility: hidden;
}

.ant-dropdown-menu-item:hover .menu_item_parent-dot {
  visibility: visible;
}

.c-empty-saved-filters {
  cursor: default !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Roboto;
  letter-spacing: 0.03em;
  padding: 10px;
  img {
    margin-top: 10px;
    height: 40px;
  }

  p {
    font-weight: 600;
    margin: 8px 0px;
  }
  span {
    font-size: 11px;
    font-weight: 500;
  }
  Button {
    span {
      font-weight: 600;
    }
    margin: 10px 0px;
    background-color: #7043ff;
    color: white;
    border-radius: 8px;
  }
  Button:hover {
    background-color: #7b53ff !important;
    color: white !important;
  }

  Button:disabled {
    background-color: #e2e2e2;
    color: white !important;
  }
  Button:disabled:hover {
    background-color: #e2e2e2 !important;
    color: white !important;
  }
}
