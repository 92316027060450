.AIGeneratePrompt {
  padding: 58px 175px;
  background-color: white;
  margin: 20px 35px 0px;
  border-radius: 8px;
}
.CampaignGenerateReport .AIGeneratePrompt {
  padding: 0;
  margin: 20px 0px 0px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #ebecf0;
  // width: 100%;
  Button {
    margin-left: 10px;
    background-color: #7043ff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    color: #ffffff;
  }

  Button:hover {
    color: white !important;
    background-color: #8763ff;
  }
  .reset {
    padding: 0px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    color: #7043ff;
  }
}
.templateHead {
  color: #0e1b40;
  text-align: center;
  font-size: 45px;
  margin: 0px 0px 24px;
}
.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cardHeader img {
  margin-bottom: 15px;
  width: 60px;
}
.cardHeader radio {
  position: absolute;
  top: 12px;
  right: 12px;
}
.cardMainContent{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.template {
  text-align: center;
  color: #666362;
  font-weight: lighter;
  font-size: 24px;
  margin: 0px 0px 48px;
}
.warmUp {
  font-size: 18px;
  color: #091e42;
  margin: 0px;
  font-weight: normal;
  // width: 70%;
}
.warmUpLorem {
  font-size: 14px;
  color: #666362;
  margin-bottom: 0px;
  //margin-top: 20px;
}
.warmUpEstimated {
  color: #a09d9c;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
  margin-top: 20px;
}
.warmUpEstimated img {
  display: block;
}
.warmUpEstimated span {
  margintop: 3px;
}
.radio {
  margin: 0px;
}

.warmUpAchieve h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(9, 30, 66, 0.6);
  font-size: 24px;
  font-weight: lighter;
}
.warmUpAchieve img {
  margin: 0px 10px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.cardDetailTitle {
  background: linear-gradient(to right, #7144ff, #b649f3);
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400 !important;
}
.cardBody {
  padding: 0px;
  border-radius: 10px;
  border: 2px solid #dfe1e6;
}
.cardStyling {
  display: flex;
  flexdirection: column;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  border: 0px;
  position: relative;
  height: 100%;
}
.cardStyling:hover {
  outline: 1px solid #7043ff;
}
.Tiles_radio {
  position: absolute;
}
.textAreaDesign {
  background: linear-gradient(to top, #b649f3, #7144ff);
  background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
  padding: 2px;
  border-radius: 10px;
}
.textAreaBodyDesign {
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  font-size: 20px;
  background-color: white;
}
.textAreaBodyDesign h1 {
  font-weight: 400;
  margin: 17px 0;
  font-size: 20px;
}
.textAreaBodyDesign.textAreaBodyDesignContent h1 {
  font-weight: 400;
  margin: 17px 0;
  font-size: 14px;
}
.textAreaBodyDesign.textAreaBodyDesignContent span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.autoComplete .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-width: 250px;
  border-bottom: 1px solid #f3f4f6;
}
.autoComplete:hover,
.autoComplete:focus {
  border-bottom: 1px solid #7546ff;
}
.autoCompleteImage {
  display: block;
  margin-left: 0;
  filter: invert(37%) sepia(7%) saturate(0%) hue-rotate(150deg) brightness(100%) contrast(100%);
}
.warmUpButton {
  text-align: center;
  margin-top: 30px;
}
.warmUpButton button {
  padding: 10px 38px !important;
  background: #7043ff;
  border-radius: 8px !important;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.001em;
  height: 48px !important;
}
.warmUpButton button:hover,
.warmUpButton button:focus {
  color: #ffffff !important;
  outline: none !important;
}

.premium_badge {
  position: absolute;
  right: 20px;
  z-index: 9;
  top: 16px;
}

.setupMethods {
  display: flex;
  justify-content: center;
  gap: 10px;
  Button {
    min-width: 150px;
  }
}

.initialContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    margin-right: 8px;
    height: 22px;
    width: 22px;
  }
  p {
    color: '#091E4299' !important;
    font-size: '18px' !important;
  }
}

.cards {
  margin-bottom: 20px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1166px) and (max-width: 1500px) {
  .cardBody{
    max-width: 50%;
  }
  
}