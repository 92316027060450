// * {
//   font-family: 'Roboto' !important;
// }

.settingsLayout {
  //   height: 100vh;
  font-family: 'Roboto' !important;
}

.sidebar {
  border-right: 1px solid #f0f0f0;
  padding-left: 20px;
}

.sidebarHeader {
  //   text-align: center;
  //   margin-left: 15px;
  margin-top: 15px;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
}

.content {
  // padding: 24px;
  background: #fff;
  min-height: 280px;
}
