.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  padding: 16px 24px;
  z-index: 1;

  .leftSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0px;
    }
    Button {
      display: flex;
      align-items: center;
      img {
        height: 13px;
      }
    }
  }

  .rightSide,
  .rightSideEdit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    Button {
      min-width: 140px;
    }
    .discard {
      color: #7043ff;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .rightSideEdit {
    Button {
      min-width: 70px;
    }
  }
}

.form {
  color: #051123;
  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: center;
    margin-bottom: 5px;
  }

  span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    line-height: 16.41px;
    letter-spacing: 0.005em;
    text-align: center;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    Button {
      min-width: 150px;
      border-radius: 8px;
    }
  }
}

.popoverContainer {
  display: flex;
  gap: 10px;
  Button {
    margin-top: 0px !important;
  }
}
