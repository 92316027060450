.card {
  border: 1px solid #dfe1e6;
  flex: 1 1 380px;
  max-width: 640px;
  .cardBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      margin-top: 0px;
      margin-bottom: 16px;
      color: var(--Natural-800, #091e42);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.028px;
    }
  }
}
.statistics {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
}

.stats {
  text-overflow: ellipsis;
}

.increase {
  display: flex;
  align-items: center;
  color: #02c3ab;
}

.noChange {
  display: flex;
  align-items: center;
  color: #97a0af;
}

.decrease {
  display: flex;
  align-items: center;
  color: #ff5c5c;
  font-size: 13px;
}

.time {
  margin-top: 1px;
  margin-left: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 19.5px;
  text-align: left;
  color: #97a0af;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 62px;
}
@media (max-width: 1250px){
  .card{
    h3{
      font-size: 8px;
    }
  }
}
@media (max-width: 1115px) {
  .card{
    flex: 1 1 50%;

  }
}
@media (max-width: 950px) {
  .card{
    flex: 1 1 0;

  }
}