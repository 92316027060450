.groupsModal {
  .ant-modal-content {
    padding: 0;
    margin: 0;
  }

  .modalLayout {
    background: none;
  }

  .modalHeader {
    background-color: #fff; // Your header color
    padding: 16px;
    font-size: 24px; // Adjust as per your design
  }

  .modalContent {
    padding: 16px;
    background-color: #f0f2f5; // Your content background color
  }
}

.groupsModal.ant-modal .ant-modal-content {
  padding: 0px !important;
  overflow: hidden;
}

.groupsModal.ant-modal .ant-modal-body {
  padding: 0px;
}
.header {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  // span {
  //   color: #7043ff;
  //   cursor: pointer;
  //   &:hover {
  //     color: #9778ff;
  //   }
  // }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
  align-items: center;
}

.rowStyle {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-top: 10px;
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 550px;
}
.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;

  input {
    background: url(../../../../assets/svg/leads_search.svg) #ffffff no-repeat;
    background-position: left 20px bottom 10px;
    padding-left: 50px;
    border: 1px solid transparent;
    border-radius: 10px;
  }

  Button {
    margin-left: 15px;
    border: 1px solid #dfe1e6;
    border-radius: 24px;
    height: 40px;
  }

  .input_box {
    background: linear-gradient(to top, #b649f3, #7144ff);
    background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
    padding: 1px;
    border-radius: 10px;
    width: 100%;
  }
}
.searchTop {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 97%;

  input {
    background: url(../../../../assets/svg/leads_search.svg) #ffffff no-repeat;
    background-position: left 20px bottom 10px;
    padding-left: 50px;
    border: 1px solid transparent;
    border-radius: 10px;
  }

  Button {
    margin-left: 15px;
    border: 1px solid #dfe1e6;
    border-radius: 24px;
    height: 40px;
  }

  .input_box {
    background: linear-gradient(to top, #b649f3, #7144ff);
    background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
    padding: 1px;
    border-radius: 10px;
    width: 100%;
  }
}

.groupsSidebar {
  span {
    color: black;
    background-color: black;
    font-size: 50;
  }
}
