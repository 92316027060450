.sider {
  &__note_text {
    color: #919191;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.07px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    article {
      color: var(--Nutrals-100, #051123);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.024px;
    }

    button {
      display: flex;
      height: 40px;
      width: 60px;
      padding: 4px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--primary-600-main, #7043ff);
      color: white;

      &:hover {
        color: white !important;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    article {
      color: var(--Natural-800, #091e42);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.07px;
      margin-bottom: 8px;
    }
  }
}

.modalRemove {
  min-height: 120px;
  background-color: white;
  padding: 0px;

  .removeTitle {
    margin-top: 20px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
  }

  .removeNote {
    margin: 20px 0;
    color: #5e6c84;
  }

  .footer {
    button {
      height: 32px;
      width: 100%;
      border-radius: 246px;
      font-size: 12px;
    }
  }
}
.contentStyle {
  min-height: 120px;
  background-color: white;
  padding: 0px;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
      margin-top: 40px;
    }

    p {
      margin: 10px 0px 8px 0px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
    }

    span {
      font-size: 14px;
      margin-bottom: 20px;
    }

    Button {
      border-radius: 8px;
      width: 200px;
      text-align: center;
      margin: 0px 5px;
      padding: 0px 5px;

      span {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
    .footer {
      display: flex;
      margin-bottom: 30px;
      margin-top: 40px;
    }
  }
}

.applyForNewOnly {
  display: flex;
  align-items: center;
  justify-content: center;
}
