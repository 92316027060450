/* @font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Medium.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
} */

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Medium.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  font-family: BlinkMacSystemFont, -apple-system, 'FbSpoilerPasEng', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #091e42;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

code {
  font-family: 'Roboto';
}

.ant-btn-default:disabled,
.ant-btn-primary:disabled {
  background-color: transparent;
}

.ant-btn-primary:disabled {
  color: white;
  background-color: var(--color-gray-01);
}

.ant-btn-default {
  color: var(--color-blue-03);
  border-color: var(--color-blue-03);
}
