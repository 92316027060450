.categorizer-component {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__title {
            font-size: 16px;
            font-weight: 600;
            color: #051123;
        }
    }
    &__body {
        margin: 16px 0;
    }
}

.groupCard {
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 10px;
    border: 1px solid #DFE1E6;
    padding: 8px 16px;
    .groupsContent {
        padding: 4px;
        background-color: #f0f2f5;
    }

    .cardHeader {
        margin-bottom: 10px;

        .cardImage {
            background: #EBECF0;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            img {
                border-radius: 50%;
                width: 40px;
                height: 40px;
            }
        }

        .groupName {
            text-overflow: ellipsis;
            font-size: 14px;
            color: #051123;
            font-weight: 500;
        }
    }

    .leads {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        line-height: normal;
        margin-bottom: 12px;
    }

    .numberOfLeads {
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;

        span {
            text-overflow: ellipsis;
        }

        img {
            width: 19px;
            margin-right: 4px;
        }
    }

    .platform {
        display: flex;
        align-items: center;
        color: #5E6C84;
        font-weight: 400;

        span {
            font-size: 12px;
            color: #97A0AF
        }

        strong {
            font-size: 12px;
            color: #5E6C84;
            margin-right: 4px;
        }

        img {
            height: 16px;
            margin-right: 4px;
        }
    }

    .cardBody {
        .groupDescription {
            color: #091E42;
            font-size: 12px;
            max-height: 42px;
            font-weight: 300;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}