.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
  width: fit-content;
  flex-direction: row;
}

.shortcutWrapper {
  background-color: #d8cef8;
  padding: 6px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.text {
  color: #5e6c84;
  font-size: 16px;
}

.keyBox {
  background-color: white;
  padding: 4px 4px;
  border-radius: 8px;
  font-size: 16px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #5e6c84;
}

.plus {
  color: #5e6c84;
  font-size: 16px;
  margin: 0 2px;
}
