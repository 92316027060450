.container {
  .matchesLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .importLeads {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #7043ff;
      font-weight: 600;
      img {
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.contactName {
  display: flex;
  flex-direction: column;
}
.Pending_btn {
  display: flex;
  align-items: center;
  border: 1px solid #ebecf0;
  border-radius: 24px;
  padding: 7px 40px;
  .Pending_btn_dot {
    background-color: #65c6f2;
    display: inline-block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    margin-right: 8px;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #091e42;
  }
}
