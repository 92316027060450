.popover {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.title {
  color: var(--Nutrals-200, #091e42);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.body {
  color: var(--Natural-700, #5e6c84);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}
.button {
  display: flex;
  height: 32px;
  width: 70px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: var(--primary-600-main, #7043ff);
  color: var(--Color, #fff);

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.028px;
}
.footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
