.card {
  border: 1px solid #dfe1e6;
  flex: 0 1 auto;
  max-width: 350px;
  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    color: var(--Natural-800, #091e42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.028px;
  }
}

.progressDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // margin-left: 10px;
  line-height: 15px;
  margin-left: 10px;
}

.progressDetails p {
  margin: 3px 0;
}

.legendRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.running {
  background-color: #19bcfe;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}

.completed {
  background-color: #02c3ab;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}

.warming {
  background-color: #f9c628;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}

.paused {
  background-color: #ff5c5c;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}

.others {
  background-color: #ebecf0;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}

.number {
  display: flex;
  min-width: 22px;
  margin-left: 6px;
  color: #4d4d4d;
  font-weight: 600;
}

.status {
  color: #97a0af;
}
//@media (max-width: 1250px){
//  .card{
//
//    font-size: 12px;
//  }
//}

@media (max-width: 1115px) {
  .card{
    flex: 1 1 50%;

  }
}

