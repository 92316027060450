.containerCollapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: default;

  .leftSide {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .headerText {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .rightSide {
    display: flex;
    align-items: center;

    span {
      color: #7043ff;
      font-weight: 600;
    }

    .disable {
      color: lightgray !important;
      cursor: not-allowed !important;
    }

    .completeIcon {
      margin-left: 12px;
    }
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.backButton {
  position: absolute;
  color: #7043ff;
  top: 40px;
  font-weight: 550;
  cursor: pointer;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardHeader img {
  margin-bottom: 10px;
  width: 50px;
}

.cardBody {
  padding: 0px;
  border-radius: 10px;
  border: 2px solid #dfe1e6;
}

.cardStyling {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  border: 0px;
  position: relative;
  height: 260px;
}

.cardStyling:hover {
  outline: 1px solid #7043ff;
}

.template {
  text-align: center;
  color: #666362;
  font-weight: lighter;
  font-size: 24px;
  margin: 0px 0px 48px;
}
.warmUp {
  font-size: 20px;
  color: #091e42;
  margin: 0px;
  font-weight: normal;
  // width: 70%;
}
.warmUpLorem {
  font-size: 14px;
  color: #666362;
  margin-bottom: 0px;
  margin-top: 20px;
}

.container {
  margin-top: 40px;
  border-radius: 8px;
  padding: 20px 40px;
  background: #fff;
  min-height: 500px;
}

.outerBorder {
  width: 100%;
  padding: 2px;
  margin-bottom: 24px;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(white, white), linear-gradient(180deg, #7144ff 0%, #b649f3 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.icpContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px;
  width: 100%;
  gap: 16px;
  /* Add some spacing between items */
}

.textSelect {
  //   flex-grow: 1; /* Allow each item to grow and take up available space */
  min-width: 150px;
  display: flex;
  align-items: center;
}

.label {
  margin-right: 8px;
  font-weight: 500;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.select {
  margin-right: 16px;
  margin-bottom: 8px;
  width: auto;
  min-width: 150px;
  max-width: 150px;
  flex-grow: 1;
}

.formRow {
  margin-bottom: 24px;
}

.textArea {
  width: 100%;
  resize: none;
}

.counter {
  display: block;
  text-align: right;
  margin-top: 4px;
  position: absolute;
  top: 0px;
  right: 10px;
  color: #091e42;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.41px;
  letter-spacing: 0.005em;
  text-align: center;
}

.input {
  width: 100%;
  margin-top: 2px;
}

.textAreaStyle {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2px;
  font-family: Roboto;
}

.textAreaLabel {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.005em;
  text-align: left;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.tabsTitle {
  color: gray;
  // position: absolute;
  // top: 8px;
  font-size: 12px;
}

.custom-collapse {
  background-color: #f5f5f5;
  border: none;

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #ebecf0;

    // width: 100%;
    button {
      cursor: pointer;
      margin-left: 10px;
      background-color: #7043ff;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: 0.002em;
      color: #ffffff;
      border-radius: 100px;
      border: 0;
      padding: 0 17px;
    }

    button:hover {
      color: white !important;
      background-color: #8763ff;
    }

    .reset {
      cursor: pointer;
      padding: 0px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.002em;
      color: #7043ff;
    }
  }
}