.node {
  min-height: 60px;
  width: 250px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 12px;
  background: white;
  display: flex;
  box-shadow: 0 0 20px 0px rgb(0, 0, 0, 0.1);
  cursor: pointer;

  .leftSide {
    display: flex;
    align-items: center;
    img {
      height: 40px;
      margin-right: 10px;
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }
    span {
      font-size: 11px;
    }
  }

  .rightSide {
  }
  &.selected {
    outline: 1px solid #7043ff;
  }

  &.expanded {
    height: auto; // This will make the height adjust to the content
    .additionalContent {
      padding: 10px; // Add padding if needed
      border-top: 1px solid #eee; // Adds a separation line between the header and the content
      // Any additional styling for the inner content goes here
    }
  }
}

.node:hover {
  background-color: #f9f7ff !important;
}

.subText {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  img {
    height: 15px !important;
    width: 15px !important;
  }
  span {
    margin-right: 3px;
  }
}

// Example styling for additional content when the node is expanded
.additionalContent {
  display: block; // Or 'flex', depending on your layout needs
  padding-top: 10px; // Add some space between the content and the rest of the node
  // Any other styles for your expanded content
}
