.header {
  padding: 14px 24px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.title {
  font-size: 20px !important;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.85);
}
