/* AIResponderSetup.module.scss */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.setupView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.centeredText {
  font-size: 18px;
  font-weight: bold;
}

.subText {
  font-size: 12px;
  color: #888;
}

.addButton {
  margin-top: 20px;
}

.dataView {
  // Add styles for data view
}
