.outcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  img {
    margin-right: 5px;
  }
}

.daniel ul li {
  height: 50px !important;
}

.truncatedText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  align-items: center;
  padding-top: 5px;
}
