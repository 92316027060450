.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  p {
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
  }
}
