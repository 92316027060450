.addLeadsModal {
  .card {
    border-radius: 8px;
    margin-bottom: 24px;
    // border: 1.5px solid #dfe1e6;
    // border: 2px solid transparent;

    &.largeCard {
      display: flex;
      align-items: center;
    }
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-left: 5px;
    min-height: 126px;
    h3 {
      font-size: 18px;
      margin: 5px 0px;
    }
    p {
      margin: 0;
    }
  }

  .smallCardsContainer {
    display: flex;
    justify-content: space-between;

    .card {
      flex: 1;

      h3 {
        font-size: 18px;
        margin: 5px 0px;
        margin-left: 5px;
      }
      p {
        margin: 0;
        margin-left: 5px;
      }
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.closeIcon {
  position: absolute;
  right: 30px;
  top: 25px;
  color: #7043ff;
  cursor: pointer;
  &:hover {
    text-shadow: #7043ff 0.4px 0 0.5px;
  }
}

.disabled {
  cursor: not-allowed;
  // filter: grayscale(1);
  pointer-events: none;
}

.comingSoon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f48fb1;

  border-radius: 8px;
  padding: 5px 10px;
  span {
    color: white;
    font-weight: 600;
  }
}
.premium {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 8px;
  padding: 5px 10px;
}

.skipPremium {
  cursor: not-allowed;
  pointer-events: none;
  // filter: grayscale(1);
}

.skip {
  cursor: not-allowed;
  filter: grayscale(1);
  pointer-events: none;
}
