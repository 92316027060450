.selectionActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  Button {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-color: #dfe1e6;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    text-align: left;
    img {
      margin-right: 4px;
    }
  }
}

.dismiss {
  text-decoration: underline;
  color: #7043ff;
  cursor: pointer;
}
