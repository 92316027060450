.node {
  height: 60px;
  width: 250px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 12px;
  background: white;
  display: flex;
  box-shadow: 0 0 20px 0px rgb(0, 0, 0, 0.1);
  cursor: pointer;

  .leftSide {
    display: flex;
    align-items: center;
    img {
      height: 40px;
      margin-right: 10px;
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }
    span {
      font-size: 11px;
    }
  }

  .rightSide {
    position: absolute;
    right: 15px;
    top: 21px;
    img {
      width: 16px;
      cursor: pointer;
    }
    img:hover {
      opacity: 0.6;
    }
  }
  &.selected {
    outline: 1px solid #7043ff;
  }
}

.node:hover {
  background-color: #f9f7ff !important;
}

.subText {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  img {
    height: 15px !important;
    width: 15px !important;
  }
  span {
    margin-right: 3px;
  }
}

.timePicker {
  position: absolute;
  top: 5px;
  left: 260px;
}

.textSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stats {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  bottom: -15px;
  display: flex;
  justify-content: space-around;
  gap: 2px;
  span {
    background-color: white !important;
    outline: 1px solid #7043ff;
    color: #7043ff;
    padding: 1px 6px;
    border-radius: 20px;
    font-size: 10px;
    box-shadow: 0px 0px 5px #7043ff;
    text-align: center;
    min-width: 85px;
    align-items: center;
  }
}

.disabled {
  filter: grayscale(100%);
  opacity: 0.5;
}

.incomplete {
  outline: 1px solid red;
}

.outline {
  outline: 1px solid #7043ff;
}

.rightSide {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.node:hover .rightSide {
  opacity: 1;
}

.aiResponder {
  border: 1px solid transparent;
  background: linear-gradient(to top, white, white), linear-gradient(to bottom, #7144ff, #b649f3);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
.aiResponder:hover {
  border: 1px solid transparent;
  background: linear-gradient(to top, rgb(249, 247, 255, 1), rgb(249, 247, 255, 1)),
    linear-gradient(to bottom, #7144ff, #b649f3);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
