.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'FbSpoilerPasEng' !important;
  padding-bottom: 20px;

  h1 {
    font-size: 24px;
    font-family: 'FbSpoilerPasEng' !important;
  }
}
.roiCalculator {
  font-family: 'FbSpoilerPasEng' !important;

  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  h3,
  span {
    margin: 0;
    // font-family: 'FbSpoilerPasEng' !important;
  }
  span {
    font-size: 14px;
  }
}

.specialFont {
  font-family: 'FbSpoilerPasEng' !important;
  margin: 0 !important;
}

.leftSide {
  max-width: 515px;
}

.sliderContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
  h1 {
    text-align: center;
    margin: 0;
    font-size: 46px;
    font-weight: normal;
    line-height: 36px;
  }
}

.inputsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.resultsContainer {
  background-color: #f3f0ff;
  border-radius: 8px;
  h1 {
    margin: 0 !important;
    line-height: normal;
  }
}

.resFlexDiv {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.resultsHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.roiBreakdown {
  margin-top: 10px;

  div {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 8px;
  }
}

.rowDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  span {
    font-family: 'Roboto' !important;
  }
}
