.saveCancel {
  display: flex;
  flex-direction: column;
  align-items: self-start;

  button {
    margin: 0;
    padding: 0;
  }
}
