.flow-preview {
  height: 98%;

  .flowStep {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 70px;
  .leftSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 32px;
      margin-right: 10px;
    }

    .text {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 1.4;
      .body {
        font-weight: 400;
        color: rgb(171, 171, 171);
      }
    }
  }

  .rightSide {
    // span {
    //   font-size: 12px;
    //   margin-right: 5px;
    //   cursor: pointer;
    //   color: #7043ff;
    // }
    Button {
      font-size: 13px;
      font-weight: 600;
      margin-right: 10px;
      cursor: pointer;
      color: #7043ff;
      border: none;
      padding: 0;
    }
    Button:disabled {
      color: #bfbfbf;
      cursor: not-allowed;
    }
  }
}

.complete,
.buildYourOwn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 70px;
  margin: auto;
  width: fit-content;
  p {
    color: #a19eaa;
    font-weight: 600;
  }
}

.buildYourOwn {
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    Button {
      z-index: 2;
      min-width: 175px;
    }
  }
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: fit-content;
}
