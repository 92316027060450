.container {
  //   position: relative;
  //   .topSection {
  //     Button {
  //       background-color: #f3f4f6;
  //       border: none;
  //       display: flex;
  //       flex-direction: row;
  //       align-items: center;
  //       gap: 5px;
  //       margin: 0px;
  //       span {
  //         font-size: 13px;
  //         font-weight: 600;
  //       }
  //       img {
  //         margin-bottom: 2px;
  //       }
  //     }
  //     Button:hover {
  //       opacity: 0.8;
  //     }
  //   }
}

.draggableItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  //   justify-content: space-between;

  width: 100%;

  margin-bottom: 10px;

  .handle {
    cursor: grab;
    margin-right: 8px;
  }

  .innerBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 8px;
    background-color: white;
    border-radius: 8px;

    .icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-right: 5px;
      Button {
        margin: 0;
        padding: 0;
      }
      .eyeIcon {
        cursor: pointer;
      }
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: #5e6c84;
}

.tag {
  color: #7043ff !important;
  margin: 0px;
}

.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 1rem;
  color: #5e6c84;
}

.rightSide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .reset {
    font-weight: 500;
    color: #7053ff;
    cursor: pointer;
    font-size: 14px;
  }
  .reset:hover {
    opacity: 0.8;
  }
}

.grayEyeMuted {
  opacity: 0.3;
  cursor: pointer;
}
.grayEyeActive {
  opacity: 1;
  cursor: pointer;
}
