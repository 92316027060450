.groupsContent {
  padding: 24px;
  background-color: #f0f2f5;
}

.groupCard {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
  // padding: 0px 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  .cardImage {
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    overflow: hidden;
    white-space: nowrap;
    .groupName {
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .leadsList {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 4px;
  }
  .leads {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 12px;
  }
  .leadsBelow {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    padding-left: 4px;
  }
  .leadsBelowList {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 8px;
  }
  .numberOfLeads {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    z-index: 333;
    span {
      text-overflow: ellipsis;
      color: black;
    }
    img {
      width: 19px;
      margin-right: 4px;
    }
  }
  .groupStatus {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    span {
      text-overflow: ellipsis;
      padding-top: 2px;
    }
    img {
      height: 16px;
      margin-right: 4px;
    }
  }
  .groupStatusLocation {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    span {
      text-overflow: ellipsis;
      padding-top: 2px;
    }
    img {
      height: 16px;
      margin-right: 4px;
    }
    .block {
      height: 16px;
      margin-right: 4px;
    }
  }
  .groupType {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    span {
      text-overflow: ellipsis;
      padding-top: 2px;
    }
    img {
      height: 16px;
      margin-right: 4px;
    }
  }
  .platform {
    display: flex;
    align-items: center;
    img {
      height: 16px;
      margin-right: 4px;
    }
  }

  .cardBody {
    .groupDescription {
      margin-bottom: 16px;
      color: #97a0af;
      font-size: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #ebecf0;
      min-height: 42px;
      max-height: 42px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .groupTags {
      min-height: 24px;
      // margin-top: 20px;
      margin-top: 16px;
      width: 100%;
      min-width: 210px;
      height: 0px;
      .tag {
        display: inline-block;
        background-color: #f2f0ff;
        color: #7043ff;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 8px;
      }
      .icpTag {
        display: inline-block;
        background-color: #2489be1a;
        color: #2489be;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
      .createdAtTag {
        display: inline-block;
        background-color: #f2f0ff;
        color: #7043ff;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
      .contactTag {
        display: inline-block;
        background-color: #c135844d;
        color: #b20064;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
      .companyTag {
        display: inline-block;
        background-color: #e2fff4;
        color: #017a6a;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
    }
    .groupTagsList {
      min-height: 24px;
      // margin-top: 20px;
      width: 100%;
      min-width: 210px;
      height: 0px;
      .tag {
        display: inline-block;
        background-color: #f2f0ff;
        color: #7043ff;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 8px;
      }
      .icpTag {
        display: inline-block;
        background-color: #2489be1a;
        color: #2489be;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
      .createdAtTag {
        display: inline-block;
        background-color: #f2f0ff;
        color: #7043ff;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
      .contactTag {
        display: inline-block;
        background-color: #c135844d;
        color: #b20064;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
      .companyTag {
        display: inline-block;
        background-color: #e2fff4;
        color: #017a6a;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 15px;
      }
    }
  }
}
.groupTypeBanner {
  position: absolute;
  top: 15px;
  right: 18px;
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: rgba(243, 120, 32, 0.1);
  color: #ff8731;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}
.pageTypeConference {
  position: absolute;
  top: 15px;
  right: 18px;
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #c0c9ff6b;
  color: #7f8ef8;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}
.searchTypeBanner {
  position: absolute;
  top: 15px;
  right: 18px;
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #2489be1a;
  color: #2489be;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}
.hashtagTypeBanner {
  position: absolute;
  top: 15px;
  right: 18px;
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #7043ff26;
  color: #7043ff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}
.pageTypeBanner {
  position: absolute;
  top: 15px;
  right: 18px;
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #c1358426;
  color: #c13584;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}
.groupImportanatStatus {
  color: #97a0af;
  font-size: 12px;
  align-items: center;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.noTags {
  margin-bottom: 16px;
  color: #97a0af;
  font-size: 12px;
  padding-bottom: 12px;
  min-height: 50px;
  max-height: 50px;
}

.progress {
  padding: 4px 0px;
}
.disabled {
  cursor: not-allowed;
  filter: grayscale(10%);
  opacity: 0.6;
  pointer-events: none;
}
.hoverableListContainer {
  width: 90% !important;
  height: 24px;
  padding-top: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  gap: 10px;
  // background-color: rgba(74, 26, 26, 0.7);
  background-color: rgb(255, 255, 255, 0.52);
  backdrop-filter: blur(3px);
  z-index: 99999;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; /* Ensures the final state of the animation is maintained */
}
.hoverableContainer {
  // position: absolute;
  // bottom: 20px;
  // right: 20px;
  // width: fit-content;
  height: 24px;
  padding-top: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  gap: 10px;
  // background-color: rgba(74, 26, 26, 0.7);
  background-color: rgb(255, 255, 255, 0.52);
  backdrop-filter: blur(3px);
  z-index: 99999;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; /* Ensures the final state of the animation is maintained */
}

/* Fade-in-up animation */
.fadeInUp {
  animation-name: fadeInUp;
}

/* Fade-out-down animation */
.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px); /* Start from 20px down */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at original position */
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0); /* Start at original position */
  }
  100% {
    opacity: 0;
    transform: translateY(15px); /* End 20px down */
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
}
