.improve {
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: #f9f7ff;
  color: #7043ff;
  padding: 4px 10px 4px 4px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  p {
    margin: 0 !important;
  }
  img {
    width: 18px;
    height: 18px;
  }
  &:hover {
    background-color: #f2eeff;
  }
}

.right_arrow {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  padding: 4px 0px;
  color: #091e42;
}
