.cabmSidebar {
  background: #7043ff !important;
  padding-top: 20px;
  position: relative;
  height: 100%;
  z-index: 2000 !important;

  ul {
    background-color: var(--color-transparent) !important;
    font-family: 'Roboto';
  }
}

.sidebarCollapsed {
  position: relative;
  width: 64px !important;
  min-width: 64px !important;

  .topSection {
    top: 0px;
    width: 64px !important;
  }

  .bottomSection {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 64px !important;
  }

  hr {
    width: 40px;
    height: 2px;
    left: 0px;
    margin-left: 12px;
    margin-bottom: 42px;
    background: var(--color-gray-02);
  }
}

.sidebarExpanded {
  position: relative;
  width: 205px !important;
  min-width: 205px !important;
  li {
    padding-left: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .topSection {
    top: 0px;
  }
  .bottomSection {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }

  hr {
    width: 80px;
    height: 2px;
    left: 0px;
    margin-left: 12px;
    margin-bottom: 42px;
    background: var(--color-gray-02);
  }
}

.buy_point li:hover {
  background-color: rgba(255, 255, 255, 20%) !important;
}

.buy_point {
  margin-bottom: 0px;
  // position: absolute;
  // bottom: 120px;
  // left: 10px;
  li {
    // height: 46.45px !important;
    padding-left: 10px !important;
    background-color: transparent !important;

    span {
      text-align: left;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.03em;
      color: var(--color-white);
    }
  }
}
