.node {
  height: 100px;
  width: 200px;
  margin: 0px 25px;
  //   width: 250px;

  //   margin: 0px 10px;
  border: 3px solid #c4c6cb;
  border-style: dashed;
  padding: 10px;
  // background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.41px;
    letter-spacing: 0.001em;
    text-align: center;
  }
}

.addNode {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }
  span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.002em;
    text-align: left;
    color: #7043ff;
  }
}

.node:hover {
  background-color: #f9f7ff !important;
}
