.newMessage {
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 8px;
  min-width: 120px;
  img {
    height: 15px;
    padding-right: 3px;
  }

  span {
    color: #091e42;
    font-weight: 500;
    font-family: Roboto;
  }
}

.channelsContainer {
  width: 100%;
  display: flex;
  padding: 6px;
  border-radius: 40px;
  background-color: #f2f0ff;
  justify-content: center;

  .iconWrapper {
    border: 1px solid #7043ff;
    width: 26px;
    height: 26px;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
  }

  img {
    padding: 2px;
    height: 20px;
    width: 20px;
    margin: 4px;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background: #e3d1ff;
}

.bottomMenuContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .bottomMenuButtons {
    display: flex;
  }
}

.outreached {
  img {
    margin-left: 10px;
  }
  p {
    margin-left: 17px;
  }

  svg {
    margin-left: 7px;
    height: 25px !important;
    width: 25px !important;
  }
}

.myContact {
  display: flex;
  width: 100%;
  flex: 1;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfe1e6;
    padding: 24px;
    background-color: white;

    &__left {
      color: var(--Nutrals-200, #091e42);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 160% */
    }

    &__right {
      &__icon {
        margin-left: 24px;
        rotate: 90deg;
        cursor: pointer;
      }
    }
  }

  &__header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      gap: 12px;

      &__text {
        color: #97a0af;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.035px;
        min-width: 140px;
        span {
          color: #000;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.035px;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 12px;

      &__filter {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        article {
          color: #7043ff;
        }
      }
    }
  }

  &__metric {
    padding: 0 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    &__item {
      border-radius: 8px;
      border: 1px solid #dfe1e6;
      background: #fff;
      display: flex;
      padding: 16px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;

      &__label {
        color: #5e6c84;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
      }

      &__value {
        color: #111;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        max-height: 36px;
        // line-height: 140%; /* 36px */
      }
    }
  }

  &__table {
    padding: 0;
    // background-color: white;
    max-width: calc(100vw - 400px);
    overflow-x: scroll;
  }
}

.emptyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 24px;

  &__title {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
  }

  &__description {
    color: #97a0af;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.07px;
  }

  &__button {
    display: flex;
    height: 40px;
    padding: 4px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: #7043ff;
    color: white;

    &:hover {
      color: white !important;
    }
  }
}

.priority {
  display: flex;
  align-items: center;
}
