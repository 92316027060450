.refineListModal {
  .ant-modal-content {
    border-radius: 10px;
  }

  .content {
    padding: 24px;
    text-align: center;
  }

  .warningIcon {
    // Include styles for the warning icon
  }

  .title {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }

  .description {
    margin-top: 8px;
    color: #666;
  }

  .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    Button {
      width: 220px;
      border-radius: 20px;
      height: 40px;
      font-weight: 600;
    }
  }

  .cancelButton {
    // Style for cancel button if needed
  }
}
