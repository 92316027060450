.templateGuildline {
  &__title {
    color: #091e42;
    font-feature-settings: 'salt' on;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  &__description {
    color: rgba(9, 30, 66, 0.6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    align-self: stretch;
  }

  &__box {
    border-radius: 10px;
    border: 1px solid #dfe1e6;
    background: var(--Natural-200, #f9fafb);
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    margin-bottom: 24px;


    article {
      &:first-child {
        overflow: hidden;
        color: #091e42;
        text-align: justify;
        font-feature-settings: 'salt' on;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
      }

      &:nth-child(2) {
        color: rgba(9, 30, 66, 0.6);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
      }
    }

    button {
      margin-left: 0;
      min-width: 80%;
      border-radius: 16px;
      border: 1px solid var(--Nutrals-600, #dfe1e6);
      background: #fff;
    }
  }

  &__download {
  }
}

@media (max-width: 1100px) {
  .templateGuildline {
    &__title {
      font-size: 13px;
    }

    &__description {
      font-size: 12px;

    }

    article {

      &:nth-child(2) {
        font-size: 12px;
      }
    }
  }
}