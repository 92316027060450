.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
}
.frame_title {
  display: flex;
  height: 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  .text {
    color: var(--Natural-800, #091e42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.07px;
    flex: 1 0 0;
  }
}
.places {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  width: 100%;
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 576px) {
  .places {
    // width: 350px;
  }
}
.places {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
.friend {
  display: flex;
  width: 132px;
  height: 60px;

  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  .text {
    overflow: hidden;
    color: var(--Natural-800, #091e42);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    flex: 1 0 0;
  }
}
.social {
  display: flex;
  width: 132px;
  height: 60px;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  .logo {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
  .text_frame {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    .text {
      overflow: hidden;
      color: var(--Natural-800, #091e42);
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.028px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
      line-clamp: 1;
    }
  }
}

.friend:hover {
  border-radius: 10px;
  background: #faf9ff;
}

.social:hover {
  border-radius: 10px;
  background: #faf9ff;
}
.friendMobile {
  display: flex;
  width: 132px;
  height: 60px;

  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  .text {
    overflow: hidden;
    color: var(--Natural-800, #091e42);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    flex: 1 0 0;
  }
}
.socialMobile {
  display: flex;
  width: 132px;
  height: 60px;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  .logo {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
  .text_frame {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    .text {
      overflow: hidden;
      color: var(--Natural-800, #091e42);
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.028px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
      line-clamp: 1;
    }
  }
}
.placesMobile {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  width: 300px;
  align-self: stretch;
  flex-wrap: wrap;

  margin-right: 10px;
}
// < 370px then friend and social mobile smaller
@media screen and (max-width: 370px) {
  .friendMobile {
    width: 120px;
  }
  .socialMobile {
    width: 120px;
  }
}
