.siderContainer {
  display: flex;
  padding: 0px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 40px;
  .siderText {
    color: var(--Nutrals-200, #091e42);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000;
  text-align: center;
  font-family: Roboto;
  height: 100%;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
    margin: 0;
    padding: 0;
  }
  span {
    color: #afafaf;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
}

.card {
  height: 100%;
  width: 100%;
  min-height: 425px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  p {
    margin: 0;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.12px;
  }
  span {
    color: #afafaf;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    // font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.doLater {
  text-decoration: underline;
  color: #7043ff;
  cursor: pointer;
}

.lottieContainer {
  // position: relative;
  // position: fixed;
  top: 0px;
  left: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  // height: fit-content;
  // max-height: 10px;
}

.innerCardBigTitle {
  width: 100%; /* Ensure the title takes up the full width of the parent */
  color: var(--Natural-800, #091e42);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.048px;
  margin: 0;
  margin-bottom: 15px;
}

.innerCardSmallTitle {
  width: 100%; /* Ensure the title takes up the full width of the parent */
  color: #97a0af;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;
  margin: 0;
  margin-bottom: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
}

.popoverContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0;
  }
  span {
    cursor: pointer;
    font-weight: 600;
    color: #7043ff;
  }
}

.container {
  height: 100%;
  justify-content: center;
  width: 100%;
}
