.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
}
.frame_title {
  display: flex;
  height: 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  .text {
    color: var(--Natural-800, #091e42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.07px;
    flex: 1 0 0;
  }
}

.friend {
  display: flex;
  height: 60px;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  .text {
    overflow: hidden;
    color: var(--Natural-800, #091e42);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    flex: 1 0 0;
  }
}
.lets {
  color: var(--Natural-900, #051123);
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}

.lets2 {
  color: var(--Nutrals-300, #5e6c84);
  text-align: center;

  /* Body/Body large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
  align-self: stretch;
}
.social {
  display: flex;
  width: 132px;
  height: 60px;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  .logo {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
  .text_frame {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    .text {
      overflow: hidden;
      color: var(--Natural-800, #091e42);
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.028px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
      line-clamp: 1;
    }
  }
}

.friend:hover {
  border-radius: 10px;
  background: #faf9ff;
}

.social:hover {
  border-radius: 10px;
  background: #faf9ff;
}
.places {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Always 3 equal columns */
  gap: 8px;
  width: 100%;
  align-self: stretch;
}
.places {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  justify-items: stretch; /* Make items stretch horizontally */
}
.firstButton {
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  background: #7043ff;
  color: var(--Nutrals-1000, #fff);

  /* Label/Label large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}
.secondButton {
  display: flex;
  height: 40px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid var(--Nutrals-600, #dfe1e6);
  color: #7043ff;

  /* Label/Label large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}
