.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #ebecf0;
  // width: 100%;
  Button {
    background-color: #7043ff;
    // margin-left: 10px;
    color: white;
    font-weight: 600;
  }

  Button:hover {
    color: white !important;
    background-color: #8763ff;
  }
}

.leftSide {
  .reset:hover {
    color: #8763ff !important;
  }
}

.rightSide {
  .white {
    background-color: white !important;
    border-color: #ccc;
    color: #7043ff !important;
    font-weight: 500;
    margin-right: 10px;
  }
  .white:hover {
    color: #7043ff !important;
  }
}
