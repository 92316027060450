.campaignSuccessContent {
  text-align: center;
  padding: 24px;
}

.campaignSuccessButtons {
  display: flex;
  flex-direction: row;
  Button {
    height: 45px;
    font-size: 16px;
    font-weight: 600;
  }
}

.campaignSuccessButtons .ant-btn-primary {
  background-color: #6c5ce7;
  border-color: #6c5ce7;
  margin-top: 24px;
}

.campaignSuccessButtons .ant-btn-link {
  padding-left: 0;
  padding-right: 0;
}
