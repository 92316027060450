.not {
  // margin-bottom: 0px;
  position: absolute;
  color: var(--Nutrals-200, #091e42);
  text-align: center;
  font-family: Roboto;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.notBox {
  // width: 75%;
  width: 600px;
  display: flex;
  justify-self: center;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
}

.notInner {
  width: 65%;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.notTextFrame {
  display: flex;
  width: 100%;
  height: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.collect {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  align-self: stretch;
}

.paraCollect {
  color: #091e42;
  text-align: center;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  align-self: stretch;
}

.collectButtonNot {
  display: flex;
  width: 206px;
  height: 40px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: #7043ff;
  color: var(--Nutrals-1000, #fff);
  font-family: Roboto;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.044px;
}
