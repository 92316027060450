.card {
  width: 168px;
  height: 168px;
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: #f9fafb;
}

.icon {
  width: 40px;
  // margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  white-space: nowrap;
}

.button {
  margin-top: 10px;
}

.selectButtonContainer {
  display: flex;
  height: 32px;
  padding: 4px 8px;
  min-width: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Nutrals-600, #dfe1e6);
  background: #fff;
}

.selectButtonText {
  // color: var(--Nutrals-500, #97A0AF);
  // font-family: Roboto;
  // font-size: 14px;
  // font-style: normal;
  // font-weight: 600;
  // line-height: 20px;
  // letter-spacing: 0.028px;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the content horizontally */
  color: var(--Nutrals-500, #97a0af);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.028px;
}

.premium {
  // filter: grayscale(100) !important;
  pointer-events: none;
}

.premiumBadge {
  position: absolute;
  right: 14px;
  top: 10px;
  z-index: 9;
  img {
    width: 20px;
    height: 20px;
  }
}
