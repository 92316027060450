.modalContent {
  max-height: 70vh;
  overflow-y: auto;
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  &::-webkit-scrollbar {
    width: 0px;
  }
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.sticky {
  position: sticky;
  z-index: 10000;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.planHeader {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  top: 0; /* Always stick at the top */
  display: grid;
  column-gap: 5px;
  row-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  padding: 8px 0px;
  background-color: #f2f0ff;
  z-index: 10; /* Higher z-index to stay above other sticky elements */
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.planName {
  text-align: center;
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  //   margin-bottom: 2px;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }

  .flexDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    p,
    span {
      font-family: 'FbSpoilerPasEng', sans-serif !important;
    }
    h1 {
      font-family: 'FbSpoilerPasEng', sans-serif !important;
      margin: 0;
      font-size: 24px;
    }
  }
  button {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
    margin-top: 8px;
    padding: 4px 8px;
    border: none;
    background-color: #6a5acd;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    p,
    span {
      font-family: 'FbSpoilerPasEng', sans-serif !important;
    }
    &:hover {
      background-color: #4b3b99;
    }
  }
}

.featureSection {
  margin-top: 0px;
  //   padding-right: 6px;
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.sectionHeader {
  top: 164px; /* Adjust this value based on the height of your planHeader */
  padding: 8px;
  font-weight: bold;
  background-color: white;
  border: 2px solid #7043ff;
  border-radius: 8px;
  z-index: 5; /* Lower z-index so it stays below the plan header */
  margin-bottom: 4px;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.featureRow {
  display: grid;
  column-gap: 5px;
  row-gap: 5px;
  margin: 2.5px 0px;
  grid-template-columns: repeat(3, 1fr);
  //   padding: 8px;
  border-bottom: 1px solid #eee;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.featureName {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  font-weight: bold;
  text-align: left;
  padding: 4px 18px;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.featureValue {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  text-align: center;
  padding: 4px 0px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 !important;
    height: 20px;
    width: 20px;
  }
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.upgradeButton {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  background: linear-gradient(to right, #6a45f6, #b84bf8);
  text-align: center;
  color: white;
  font-weight: 600;
  padding: 10px 0px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  width: 80%;
  &:hover {
    opacity: 0.8;
  }
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.activePlan {
  background: linear-gradient(to right, #aaaaaa, #9b9b9b);
  cursor: not-allowed;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}
