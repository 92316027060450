.headline {
  color: #000;

  /* Headline/Headline small */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  align-self: stretch;
}
.para {
  color: var(--Nutrals-300, #5e6c84);

  /* Body/Body large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
  align-self: stretch;
}
.cancel {
  display: flex;
  height: 32px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--Nutrals-600, #dfe1e6);
  /* Label/Label large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  width: 80px;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}
.joined {
  display: flex;
  height: 32px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--primary-600-main, #7043ff);
  color: var(--Nutrals-1000, #fff);

  /* Label/Label large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}
