.card {
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 10px;
  background: var(--Color, #fff);
  overflow: hidden;
  height: 100%;
  width: 100%;
  // margin-top: -15px;
  max-width: 540px;

  /* Card dropshadow */
  box-shadow: 0px 7.293px 21.878px 0px rgba(0, 0, 0, 0.05);
  z-index: 10;
}
.cardMobile {
  display: flex;
  padding: 64px;
  flex-direction: column;
  margin-top: 45px;
  margin-bottom: env(safe-area-inset-bottom);
  align-items: center;
  gap: 32px;
  border-radius: 10px;
  background: var(--Color, #fff);
  overflow: hidden;
  height: 100%;
  width: 100%;
  // margin-top: -15px;
  max-width: 540px;

  /* Card dropshadow */
  box-shadow: 0px 7.293px 21.878px 0px rgba(0, 0, 0, 0.05);
  z-index: 10;
}
.icon {
  width: 100.002px;
  height: 92.769px;
  flex-shrink: 0;
}
.title {
  color: var(--Natural-900, #051123);
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
}
.we {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.we_text {
  color: var(--Natural-700, #5e6c84);
  text-align: center;
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
}
.resend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.timer_text {
  color: var(--Natural-600, #97a0af);
  text-align: center;
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  width: 320px;
}
.button {
  display: flex;
  height: 40px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.buttonMobile {
  display: flex;
  height: 40px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  Button {
    border-radius: 100px !important;
  }
}
.change {
  display: flex;
  height: 40px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  .text {
    display: flex;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--primary-600-main, #7043ff);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
  }
}
.great_frame {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.great_text {
  color: var(--Natural-700, #5e6c84);
  text-align: justify;
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1 0 0;
}
