.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  //   background-color: lightblue;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  p,
  span,
  h2,
  Title {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.cardsContainer {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  font-family: 'FbSpoilerPasEng', sans-serif !important;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.selector {
  p,
  span {
    font-family: 'Roboto', sans-serif !important;
  }
}
.selectorMobile {
  p,
  span {
    font-family: 'Roboto', sans-serif !important;
  }
}
.compare {
  display: flex;
  align-items: center;
  justify-content: center;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
  span {
    color: #7043ff;
    cursor: pointer;
  }
  span:hover {
    opacity: 0.8;
  }
}
.roboto {
  font-family: 'Roboto', sans-serif !important;
  p,
  span {
    font-family: 'Roboto', sans-serif !important;
  }
}
.cancel {
  p {
    cursor: pointer;
  }
  p:hover {
    opacity: 0.7;
  }
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.lottieContainer {
  position: fixed;
  top: 0px;
  p,
  span {
    font-family: 'FbSpoilerPasEng', sans-serif !important;
  }
}

.notificationDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 25px;
  text-align: center;
  .flexDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    span {
      font-size: 16px;
    }
  }
  p {
    font-size: 32px;
    margin: 0px;
    font-weight: 600;
    text-align: center;
  }

  img {
    height: 100px;
    width: 100px;
  }

  Button {
    padding: 0px 60px;
    font-weight: 600;
  }
}
