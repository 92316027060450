.loadMessagesContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h3 {
    margin: 0;
  }
}

.divCard {
  padding: 5px 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); // Semi-transparent background
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease; // Smooth transition
  color: white;
  text-align: center;
}

.card:hover .overlay {
  opacity: 1; // Show overlay on hover
}

.addButton {
  background-color: #7043ff; // Button background color
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}
