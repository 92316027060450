.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin-top: 10px;
  color: #7043ff;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
