.smallLogoutContainer {
  position: fixed;
  //   position: relative;
  //   height: 10%;
  z-index: 50 !important;

  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  //   width: 250px;
}
@media screen and (max-width: 768px) {
  .smallLogoutContainer {
    position: relative;
  }
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.logoutButton {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  //   padding: 10px;
  border-radius: 20px;
  transition: all 0.3s ease;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
  }
}

.trialExtensionButton {
  width: 100%;
  color: #7043ff;
  font-weight: bold;
  font-size: 12px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}
