.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  img {
    height: 55px;
    width: 55px;
  }

  .title {
    margin: 0px;
    color: #051123;
    font-weight: 600;
    font-size: 32px;
    line-height: 1;
  }

  .description {
    text-align: center;
    color: #5e6c84;
    margin: 0;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    .confirmButton {
      width: 70%;
      height: 40px;
      background-color: white;
      color: #5e6c84;
      border: 1px solid #dfe1e6;
    }

    .cancelButton {
      width: 70%;
      height: 40px;
      background-color: #7043ff;
      color: white;
      border: none;
    }
  }
}
