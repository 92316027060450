.collectButton {
  Button {
    margin: 0;
    height: 30px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.eyeWrapper {
  a {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    align-items: center; /* Center items vertically */
    transition: width 0.5s, border-radius 0.5s; /* Define transitions for width and border-radius */
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #dfe1e6;
    color: #7043ff !important;
    font-size: 12px;
  }

  a:hover {
    width: 90px; /* Expand the width on hover */
    transition-delay: 0.05s;
    border-radius: 20px; /* Change border-radius on hover */
  }

  a::before,
  a::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    transition: transform 0.5s; /* Add transition for rotation and translation */
  }

  a::before {
    right: 25px;
  }

  a::after {
    left: -50px;
    transform: rotate(45deg) translate(-50px, 50px);
  }

  a:hover::before {
    transform: rotate(45deg) translate(50px, -50px);
  }

  a:hover::after {
    transform: rotate(45deg) translate(50px, -50px);
    transition-delay: 1s;
  }

  a img {
    visibility: visible; /* Ensure the image is always visible */
    transition: transform 0.5s; /* Smooth transition for transform */
  }

  a span {
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    margin-left: 7px;
    font-size: 14px;
    transition: opacity 0.1s, transform 0.2s; /* Smooth transition for text appearance */
  }

  a:hover span {
    visibility: visible;
    opacity: 1;
    transform: translateX(0); /* Bring the text back to position */
    transition-delay: 0.1s; /* Add delay for smoother text appearance */
  }
}
