.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  margin: 0 auto;
  height: 100%;

  .logo {
    position: absolute;
    top: 5%;
    left: 4%;

    img {
      height: 40px;
    }
  }
}

.content {
  text-align: center;
  width: 100%;
}

.title {
  font-size: 48px;
  font-weight: 700;
  color: #1a1a3d;
  margin: 1rem 0;
}

.subtitle {
  font-size: 22px;
  color: #091e42;
  margin-bottom: 2rem;
  line-height: 30px;
}

.videoWrapper {
  position: relative;
  width: 75%;
  padding-bottom: calc(75% * 9 / 16); // Maintain 16:9 aspect ratio
  background: #f0f0f0;
  border-radius: 20px;
  margin-bottom: 2rem;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto; // Center the video horizontally
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; // Spread the buttons to match the width of the video
  width: 75%; // Match the width of the video
  margin: 2rem auto 0; // Center and add spacing
  gap: 5%;
}

.skipButton {
  color: #7043ff;
  border-radius: 20px !important;
  height: 75px;
  width: 100%;
  border: 1px solid #7043ff !important;
  span {
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline !important;
  }
}

.startButton {
  border-radius: 20px !important;
  height: 75px;
  width: 100%;
  font-size: 28px !important;
  font-weight: 600 !important;
  background-color: #7043ff !important;
  border: none !important;
  color: white !important;
  cursor: pointer !important;
  z-index: 10;
  &:hover {
    opacity: 0.8;
  }
}

.calendlyContainer {
  position: fixed;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.waitingButton {
  border-radius: 20px !important;
  height: 75px;
  width: 100%;
  font-size: 20px !important;
  font-weight: 600 !important;
  z-index: 10;
}
