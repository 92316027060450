.campaignFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  span {
    font-weight: 600;
  }

  Button {
    margin-left: 10px;
    border-radius: 8px;
  }

  .rightSide {
    display: flex;
    align-self: start;
    Button {
      justify-self: end;
      background-color: #7043ff;
      color: white;
      border-radius: 24px;
      margin: 0;
    }
    Button:hover {
      background-color: #9676ff;
      color: white !important;
    }
  }
//  .leftSide{
//display: flex
//  }
}

.newCampaignButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 5px;
  }
}