.input {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  align-items: center;
  padding-right: 10px;
  Button {
    margin-left: 20px;
  }
}

.rowStyle {
  width: calc(100% + 10px);
  overflow-y: auto;
  padding-right: 10px;
  height: 100%;
  // overflow: hidden;
  // max-height: 613px;
  // min-height: 613px;
  align-items: flex-start;
  align-content: flex-start;
}

.colStyle {
  // flex: 1 1 auto;
}

.category {
  span {
    color: black;
  }
}

.platforms {
  display: flex;
  margin-bottom: 18px;
}

.categoryHeader {
  color: black;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  .leftSide {
  }

  .rightSide {
    color: #7043ff;
    cursor: pointer;
    padding: 5px;
    border-radius: 12px;
    &:hover {
      background-color: #f3f4f6;
    }
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 100%;
  color: black;
  text-align: center;
  gap: 10px;
  p {
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    line-height: normal;
  }
}
.not {
  // margin-bottom: 50px;
  position: absolute;
  color: var(--Nutrals-200, #091e42);
  text-align: center;
  font-family: Roboto;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 52.083% */
}
.notOther {
  margin-bottom: 200px;
  position: absolute;
  color: var(--Nutrals-200, #091e42);
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 52.083% */
}
.notBox {
  width: 75%;
  display: flex;
  justify-self: center;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
}
.notInner {
  width: 65%;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.notTextFrame {
  display: flex;
  width: 100%;
  height: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}
.collect {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  align-self: stretch;
}
.paraCollect {
  color: #091e42;
  text-align: center;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 145.455% */
  align-self: stretch;
}
.collectButtonNot {
  display: flex;
  width: 206px;
  height: 40px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: #7043ff;
  color: var(--Nutrals-1000, #fff);
  font-family: Roboto;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 90.909% */
  letter-spacing: 0.044px;
}
.mainExploreTitle {
  color: var(--Natural-700, #5e6c84);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.027px;
}
.mainExploreSeeMore {
  color: var(--primary-600-main, #7043ff);

  /* Label/Label large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
  cursor: pointer;
}

// Leads Styles
.stepContentContainer {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.headerContainer {
  padding: 10px 0px;
  width: 100%;
  border-bottom: 1px solid #ebecf0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
  gap: 10px;
  img {
    height: 24px;
    width: 24px;
  }
  p {
    font-weight: 600;
    margin: 0;
    font-size: 18px;
  }
}

.stepContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  h3 {
    color: #333;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
  }
}

.input {
  margin-bottom: 1rem;

  &:focus {
    border-color: #007bff;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    // margin-bottom: 5px;
  }
}

.firstScreen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  h3 {
    color: #333;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
    margin: 10px 0px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 0px 25px 0px;
  span {
    margin-top: 10px;
    color: #7043ff;
    font-weight: 600;
  }
}

.container {
  padding: 20px 0px;
}

.actions {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  Button {
    width: 150px;
    border-radius: 8px;
    height: 35px;
    font-weight: 600;
    margin: 0;
    border-radius: 20px;
  }
  .cancelButton {
    font-size: 12px;
    border: none;
    box-shadow: none;
    height: fit-content;
    padding: 0;
    width: fit-content;
    span {
      text-decoration: underline;
      color: #4f91ff;
      font-weight: 400;
    }
  }
}

.timer {
  display: inline;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  span {
    font-size: 14px !important;
    line-height: 1 !important;
  }
  p {
    margin: 0px;
    padding-top: 3px;
    // font-weight: ;
  }
}

.carousel {
  // margin-top: 100px;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e6c84;
    margin-bottom: 15px;
  }
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  color: red;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 6px;
  img {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    Button {
      font-weight: 600;
    }
  }
}

.unknown {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0px 40px;
  align-items: center;
  p {
    font-weight: 600;
    margin: 0;
  }

  .centered {
    text-align: center;
  }
}

.loaderContainer {
  position: relative;
  margin-top: 99px;
  img {
    position: absolute;
    top: 7px;
    left: 7px;
    background-color: transparent;
  }
  .loader {
    --border-width: 8px;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    --mask: radial-gradient(
      farthest-side,
      transparent calc(100% - var(--border-width) - 0.5px),
      #000 calc(100% - var(--border-width) + 0.5px)
    );
    -webkit-mask: var(--mask);
    mask: var(--mask);

    background: linear-gradient(to top, #7144ff, #b649f3) 100% 0/50% 100% no-repeat,
      linear-gradient(#b649f3 50%, transparent 95%) 0 0/50% 100% no-repeat;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loadingScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 200px;

  // .truncatedText {
  //   font-weight: 600;
  //   font-size: 26px;
  //   margin: 10px 0;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   max-width: 100%;
  // }
  .truncatedText {
    font-weight: 600;
    font-size: 45px;
    margin: 10px 0;
    white-space: normal; /* Allows automatic line wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    max-width: 700px;
    word-break: break-word; /* Breaks long words onto the next line if needed */
  }

  p {
    font-weight: 600;
    font-size: 32px;
    margin: 10px 0;
  }

  span {
    color: #5e6c84;
    height: 45px;
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  p {
    font-weight: 600;
    font-size: 26px;
    margin: 10px 0px;
  }
  span {
    color: #5e6c84;
    // height: 45px;
  }
  img {
    height: 60px;
    width: 60px;
  }
}

.bullet {
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 1.5em;
}

.bodyContainer {
  display: flex;
  padding: 0px 60px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  p {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  .description {
    color: var(--Nutrals-300, #5e6c84);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.08px;
    text-align: center;
  }

  .inputCode {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 250px;
    .title {
      font-weight: 600;
      color: #5e6c84;
    }
  }
}

.countdown {
  text-align: center;
  display: flex;
  flex-direction: row;
  span {
    font-size: 12px !important;
    line-height: 1 !important;
    color: #5e6c84;
  }
}

.carousel {
  margin-bottom: 100px;
  p,
  span {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e6c84;
    margin-bottom: 15px;
  }
}
