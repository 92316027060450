.tableContainer {
  padding: 0px 20px;
  background-color: #f3f4f6;
  height: 100%;
}

.statusConnected {
  color: green;
}

.statusDisconnected {
  color: red;
}

.moreOptions {
  cursor: pointer;
  color: #1890ff;
}

.platformIcon {
  width: 35px;
  height: 35px;
  margin-right: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  .leftSide {
    display: flex;
    align-items: center;
  }

  .rightSide {
    display: flex;
    align-items: center;
    Button {
      height: 35px;
    }
  }
}
.upgradeNow {
  display: flex;
  height: 40px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  border-radius: 24px;
  background: #7043ff;

  color: var(--Nutrals-1000, #fff);

  /* Label/Label large */
  font-family: Roboto;
  font-size: 18px;
  font-weight: bolder;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}
.talkToSales {
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  color: #7043ff;

  /* Label/Label large */
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: bolder;
  line-height: 20px; /* 142.857% */
}
.talkToSales:hover {
  color: #7043ff !important;
  background: transparent !important;
}
.talkToSales:focus {
  color: #7043ff !important;
  background: transparent !important;
}
