.container {
  padding-top: 10px;
  .scheduling {
    padding-left: 20px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-left: -10px !important;
      p {
        margin: 0px;
        font-size: 16px;
        color: #091e42;
        opacity: 65%;
        padding-right: 5px;
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      .radio {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        p {
          margin: 0;
          font-weight: 600;
        }
        span {
          color: #091e42 !important;
          opacity: 60%;
        }
      }
      .checkbox {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;
        // padding-left: 10px;
        .body {
          display: flex;
          flex-direction: column;
          margin-left: 18px;
          // cursor: pointer;
          p {
            margin: 0;
            font-weight: 600;
          }
          span {
            color: #091e42 !important;
            opacity: 60%;
          }
        }
      }
    }
  }
  .configuration {
    margin-top: 20px;
    padding-left: 15px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-left: -5px !important;
      p {
        margin: 0px;
        font-size: 16px;
        color: #091e42;
        opacity: 65%;
        padding-right: 5px;
      }
    }
    .body {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }
}

.customSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  Button {
    border-radius: 8px;
    background-color: #7043ff;
    color: white;
  }

  Button:hover {
    background-color: #825dff !important;
    color: white !important;
  }
}
.popoverContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 6px;
  max-width: 450px;

  img {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    width: 100%;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    Button {
      font-weight: 600;
    }
  }
}
