.cardWrapperMobile {
  padding: 14px;
  display: flex;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.contentMobile {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  justify-content: center;
}
.card {
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Color, #fff);
  height: 100%;
  width: 100%;
  /* Card dropshadow */
  box-shadow: 0px 7.293px 21.878px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-top: -35px;
  z-index: 10;
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}
.cardMobile {
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-top: 30px;
  align-items: center;
  gap: 15px;
  align-self: center;
  border-radius: 10px;
  background: var(--Color, #fff);
  height: 100%;
  width: 100%;
  /* Card dropshadow */
  box-shadow: 0px 7.293px 21.878px 0px rgba(0, 0, 0, 0.1);
}
.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.term {
  align-self: stretch;
  color: var(--Natural-600, #97a0af);
  text-align: center;
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .underline {
    color: var(--Natural-600, #97a0af);
    font-feature-settings: 'salt' on;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
}
.already {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Natural-600, #97a0af);
}
.log {
  color: var(--Link, #005af4);
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.titleFrame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.title {
  color: var(--Natural-900, #051123);
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
}
.subTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.subTitleText {
  color: var(--Natural-600, #97a0af);
  text-align: center;
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  width: 280px;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
}
.first_last {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}
.first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  .first_text {
    color: var(--Natural-800, #091e42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.07px;
    height: 24px;
    align-self: stretch;
  }
}
.last {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  .last_text {
    color: var(--Natural-800, #091e42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.07px;
    height: 24px;
    align-self: stretch;
  }
}
.email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  .emailTitle {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .emailText {
      color: var(--Natural-800, #091e42);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.07px;
      flex: 1 0 0;
    }
  }
}

.outerLeftContainer {
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
