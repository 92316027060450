.socialMediaCard {
  width: 175px;
  height: 160px;
  min-width: 135px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.connected {
    outline: 1px solid #7043ff;
    background-color: #fcfbff;
  }
  &.disconnected {
    outline: 1px solid #dfe1e6;
  }
}

.socialMediaCard:hover {
  background-color: #f8f8fe;
}

.platformLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
  img {
    height: 32px;
    width: 32px;
    margin-bottom: 10px;
  }
  &.disconnected {
    opacity: 0.3;
  }
}

.accounts {
  display: flex;
  align-items: center;
  border: 1px solid #dfe1e6;
  border-radius: 24px;
  padding: 3px 10px;
  margin-bottom: 3px;

  p {
    margin: 0;
  }
}

.dot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-right: 8px;
  &.connected {
    background-color: #50e2ba;
  }
  &.disconnected {
    background-color: #f79d00;
  }
}

.premium_badge {
  position: relative;
  // right: 0px;
  z-index: 9;
  margin-bottom: 5px;
  height: 20px;
  left: 50px;
}

.disableSelection {
  pointer-events: none;
}
