.container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: center;
    margin-bottom: 5px;
  }

  span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.005em;
    text-align: center;
  }
}
