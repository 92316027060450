.verticalDots {
  position: absolute;
  left: -26px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.dot {
  border-radius: 50%;
  transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
}

.currentDot {
  // background-color: #7043ff !important;
}
