.myContact .fancyTable .ant-table-body {
  max-height: calc(100vh - 420px) !important;
  min-height: calc(100vh - 420px) !important;
  height: calc(100vh - 420px) !important;
}

.myContact .fancyTable .tblLogoName img {
  filter: unset !important;
}

.myContact .fancyTable .tblFieldText.company_field img {
  filter: unset !important;
}

.fancyTable .tblFieldText.company_field img {
  filter: unset !important;
}

.myContact .ant-table-cell {
  padding: 16px !important;
}

.myContact .fancyTable .ant-table-body {
  max-height: calc(100vh - 420px) !important;
  min-height: calc(100vh - 420px) !important;
  height: calc(100vh - 420px) !important;
}

.myContact .leadTable .ant-table-body {
  max-height: calc(100vh - 350px) !important;
  min-height: calc(100vh - 350px) !important;
  height: calc(100vh - 350px) !important;
  margin-bottom: 25px;
}

.fancyTable .ant-table-thead > tr > th::before {
  display: none !important;
}

.myContact .fancyTable .tblLogoName img {
  filter: unset !important;
}

.myContact .fancyTable .tblFieldText.company_field img {
  filter: unset !important;
}

.fancyTable .tblFieldText.company_field img {
  filter: unset !important;
}

.myContact .ant-table-cell {
  padding: 16px !important;
}

.marginTable.my_contact_table.with-tableAction .fancyTable .ant-table-body {
  max-height: calc(100vh - 510px) !important;
  min-height: calc(100vh - 510px) !important;
  height: calc(100vh - 510px) !important;
}
.marginTable.my_contact_table.no-data .fancyTable .ant-table-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none !important;
}

.marginTable.my_contact_table.no-data .fancyTable .ant-table-placeholder .ant-table-cell {
  border: none !important;
}

.marginTable.my_contact_table.no-data .fancyTable .ant-table-body {
  border: 1px solid #dfe1e6 !important;
  border-top: none !important;
}

.marginTable.my_contact_table.with-tableAction .fancyTable .ant-table-body {
  max-height: calc(100vh - 510px) !important;
  min-height: calc(100vh - 510px) !important;
  height: calc(100vh - 510px) !important;
}
.marginTable.my_contact_table.no-data .fancyTable .ant-table-placeholder .ant-table-cell {
  border: none !important;
}

.marginTable.my_contact_table.no-data .fancyTable .ant-table-body {
  border: 1px solid #dfe1e6 !important;
  border-top: none !important;
}

.csvPreviewTable .ant-table-thead > tr > th {
  background: transparent !important;
}

.csvPreviewTable .ant-table-thead .ant-table-cell {
  white-space: nowrap;
}

.csvPreviewTable .ant-table-cell {
  padding: 12px 16px !important;
  overflow: hidden;
  color: var(--Natural-700, #5e6c84);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.06px;
}

.csvPreviewTable .ant-table-row .ant-table-cell:not(:first-child) {
  min-width: 200px;
}

.csvPreviewTable .ant-table-container {
  border: 1px solid #dfe1e6;
  border-radius: 4px 4px 0px 0px;
}

.importAndMappingTable .ant-table-thead > tr > th {
  background: transparent !important;
}

.importAndMappingTable .ant-table-wrapper table {
  border-radius: 0 0 8px 8px !important;
}

.importAndMappingTable .ant-table-content table {
  border: 1px solid #dfe1e6 !important;
  border-bottom: none !important;
}

.importAndMappingTable .ant-table-body table {
  border-bottom: none !important;
  border-radius: 0px 0px 4px 4px;
}

.importAndMappingTable .ant-table-cell {
  padding: 16px !important;

  overflow: hidden;
  color: var(--Natural-700, #5e6c84);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.06px;
}

.importAndMappingTable .ant-table-thead .ant-table-cell {
  overflow: hidden;
  color: var(--Nutrals-200, #091e42);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}

.ExposedEnrichModal .ant-modal-content {
  padding: 0 !important;
}

.ExposedEnrichModal .ant-modal-header {
  margin-bottom: 0 !important;
}
