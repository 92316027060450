// .statsContainer {
//   background-color: red;
//   width: 100%;
//   height: 168px;
//   margin-bottom: 20px;
// }

// .container {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 20px;
// }

.container {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  flex-wrap: wrap;

}
@media (max-width: 1420px){
  .container{
    gap: 4px;
  }
}
