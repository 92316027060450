.card {
  display: flex;
  gap: 20px;
  //   background: #fff;
  //   border-radius: 12px;
  //   padding: 20px;
  //   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  //   overflow: hidden;
}

.emptyCard {
  display: flex;
  background: rgba(112, 67, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  .emptyCardText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-color: #fffbf6;
    border-radius: 12px;
    height: 60%;
    width: 80%;
    gap: 25px;
    p {
      font-size: 48px;
      margin: 0;
    }
    span {
      font-size: 20px;
    }
    Button {
      height: 50px;
      width: 180px;
      color: black;
      &:hover {
        color: black;
        background-color: white;
        opacity: 0.8;
      }
    }
  }
}

.left {
  flex: 1;
  max-width: 60%;
  height: 100%;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}

.right {
  flex: 1;
  max-width: 40%;
  background: #f5f5f5;
  border-radius: 20px 0px 0px 20px;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}

.content,
.commentContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 28px;
  height: 100%;
  h2,
  p {
    margin: 0;
  }
}

.commentContent {
  padding-left: 28px;
}

.prospectInfo,
.commentInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.commentInfo {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.post {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stamp {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 57%;
  bottom: 23%;
  z-index: 10;
}

.source {
  font-size: 12px;
  color: #888;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0;
  .platformIcon {
    width: 25px;
    height: 25px;
  }
}

.name {
  font-size: 18px;
  font-weight: bold;
}

.timestamp {
  font-size: 12px;
  color: #aaa;
}

.title {
  font-size: 14px;
  color: #555;
}

.topicWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.topic {
  display: flex;
  align-items: center;
  background: #141414;
  color: white;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 12px;
  width: fit-content;
  gap: 3px;
  img {
    margin-left: -5px;
    height: 10px;
    width: 18px;
  }
}

.score {
  color: #4caf50;
  font-size: 14px;
  background-color: #cff7d3;
  padding: 3px 6px;
  border-radius: 4px;
}

.postTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.details {
  list-style: none;
  padding: 0;
  margin: 0;
}

.details li {
  font-size: 14px;
  margin: 4px 0;
  color: #5b5e63;
}

.reactions {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #8965fb;
  background-color: #ebebeb;
  width: fit-content;
  padding: 2px 10px 2px 6px;
  border-radius: 20px;
  gap: 5px;
  text-decoration: underline;
}

.profileHeader {
  display: flex;
  align-items: center;
  gap: 15px;
  p,
  h2 {
    margin: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .generalData {
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 10px;
    }

    .location {
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 2px;
      img {
        width: 15px;
        height: 15px;
        margin-bottom: 1px;
      }
    }

    .fireBadge,
    .personaBadge {
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 4px;
      border-radius: 4px;
      padding: 2px 6px;
      font-weight: 600;
      img {
        // width: 13px;
        height: 13px;
      }
    }
    .personaBadge {
      color: #014c35;
      img {
        margin-bottom: 1px;
      }
    }
    .fireBadge {
      img {
        margin-bottom: 2px;
      }
    }

    .fire-1 {
      background-color: #98eff9;
    }
    .fire-2 {
      background-color: #dcf999;
    }
    .fire-3 {
      background-color: #f9e298;
    }
    .fire-4 {
      background-color: #f9b898;
    }
    .fire-5 {
      background-color: #f99898;
    }

    // Persona Badge Colors
    .persona-0 {
      background-color: #f7cfcf;
      color: #7a0000;
    }
    .persona-25 {
      background-color: #f7f6cf;
      color: black;
    }
    .persona-50 {
      background-color: #eef7cf;
      color: black;
    }
    .persona-75 {
      background-color: #cff7d3;
      color: #014c36;
    }
  }
}

.profilePic {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.summary {
  background: #fff;
  padding: 10px 10px 10px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  margin: 10px 16px 0px 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom, #7043ff, #fe50e6);
    border-radius: 4px 0 0 4px;
  }
}

.summaryTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  gap: 8px;
  img {
    width: 18px;
    height: 18px;
  }
}

.summaryContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;

  .leftSummaryContent {
    padding-right: 5px;
    min-width: 40px;
    img {
      width: 30px;
      height: 30px;
    }
  }

  .rightSummaryContent {
    display: flex;
    flex-direction: column;
    gap: 3px;
    p {
      margin: 0;
      font-size: 14px;
    }
  }
}

.descriptionText {
  font-size: 16px;
  display: inline; // Ensures text remains inline
}

.truncated,
.truncatedExtra {
  display: -webkit-box;
  -webkit-line-clamp: 2; // Limit to 2 lines
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncatedExtra {
  -webkit-line-clamp: 7;
}

.expanded {
  -webkit-line-clamp: unset;
}

.about {
  margin-top: 10px;
  padding: 0px 20px;

  p {
    margin: 0;
    font-size: 16px;
  }

  .summaryText {
    display: -webkit-box;
    -webkit-line-clamp: 2; // Restrict to 3 lines
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .expanded {
    -webkit-line-clamp: unset;
  }
}

.showMore {
  color: #7043ff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.experience {
  margin-top: 10px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    margin: 0;
    font-size: 16px;
  }

  .experienceItem {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .company {
      font-size: 14px;
    }
    .duration {
      font-size: 12px;
    }
  }
}

.experience div {
  margin-bottom: 6px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  img {
    margin-left: 4px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}
