.custom-header {
  padding: 16px 24px;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  h1 {
    margin: 0;
    font-size: 24px;
  }

  .leftSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    // align-items: start;
    margin-top: 1px;
    Button {
      display: flex;
      align-items: center;
      img {
        height: 13px;
      }
    }
    .campaign {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .top {
        font-size: 12px;
      }
      .bottom {
        font-size: 18px;
        font-weight: 600;
        p {
          margin: 0;
        }
      }
    }
  }
  .rightside {
    display: flex;
    align-items: center;
    Button {
      background-color: #7043ff;
      color: white;
      // margin-left: 25px;
    }
    Button:hover {
      background-color: #8763ff;
      color: white;
    }
    Button:disabled {
      background-color: #97a0af;
    }

    .customButton {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #7043ff;
      font-weight: 600;
      margin-left: 25px;

      img {
        margin-right: 5px;
      }
      &:hover {
        opacity: 0.75;
      }
    }
  }
}

.headerStatus {
  display: flex;
  span {
    margin-left: 10px;
    background-color: #f1f9fe;
    color: #65c6f2;
    padding: 0px 10px;
    font-size: 12px;
    align-items: center;
    display: flex;
    border-radius: 4px;
  }
}
