.card {
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #dfe1e6;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  position: relative;
  img {
    width: 100%;
    border-radius: 1px;
    border-bottom: 2px solid #dfe1e6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .absoluteImg {
    position: absolute;
    height: 20px;
    width: 20px;
    border-bottom: none !important;
    top: 10px;
    right: 10px;
  }
}

.description {
margin: 6%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  margin: 0;
  font-size: 18px;
}

.pDescription {
  color: rgba(0, 0, 0, 0.65);
  min-height: 65px;
  font-size: 14px;
}

.actionButton {
  width: fit-content !important;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 4px 24px !important;
  overflow: hidden;
  margin: 0;
}

.buttonText {
  display: block;
  transition: transform 0.3s ease;
}

.textShift {
  transform: translateX(-10px);
}

.rightArrow {
  position: absolute;
  right: 10px;
  // margin-left: 3px;
  opacity: 0;
  transform: translateX(-5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.visible {
  opacity: 1;
  transform: translateX(0);
}
