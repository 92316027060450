.container {
  width: 100%;
  height: 100%; // Make container fill available height
  padding: 30px;
  display: flex; // Enable flex layout
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  max-height: 100%; // Prevent card from growing beyond container
  overflow: hidden; // Hide overflow on card itself

  :global {
    .ant-card-body {
      height: 100%;
      padding: 100px;
      overflow-y: auto; // Enable scrolling on card body
      scrollbar-width: none; // For Firefox: hides the scrollbar
      -ms-overflow-style: none; // For Internet Explorer/Edge: hides the scrollbar
      &::-webkit-scrollbar {
        width: 0px; // For WebKit browsers: hides the scrollbar
        height: 0px;
        background: transparent; // Optional: transparent background
      }
    }
  }
}

.web3Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  max-height: 100%; // Prevent card from growing beyond container
  overflow: hidden; // Hide overflow on card itself
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 0; // Enable proper flex behavior
}

.subtitle {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0 !important;
}

.videoContainer {
  margin-top: -20px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  height: 0;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
