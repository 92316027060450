.groupsModal {
  .ant-modal-content {
    border-radius: 10px;
  }

  .modalLayout {
    background: none;
  }

  .modalHeader {
    background-color: #fff; // Your header color
    padding: 16px;
    font-size: 24px; // Adjust as per your design
  }

  .modalContent {
    padding: 16px;
    background-color: #f0f2f5; // Your content background color
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
  align-items: center;
}
.container {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  min-height: 681px;
  background-color: white;
  border-right: 1px solid #dfe1e6;
}
.title {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
