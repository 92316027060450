.platformTag {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 14px;
  color: #5e6c84 !important;
  margin-right: 8px;
  width: fit-content;
  justify-content: space-between;

  img {
    margin-right: 4px;
    width: 22px;
  }
  &:hover {
    // box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}

.facebook {
  background-color: #3c5a9a1a;
}

.instagram {
  background-color: #c135841a;
}

.twitter {
  background-color: #0000001a;
}

.linkedin {
  background-color: #1c84bc1a;
}

.telegram {
  background-color: #35ade11a;
}

.discord {
  background-color: #5865f21a;
}

.border {
  outline: 1px solid #7043ff;
}
