.container {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 1px !important;
  }
}

// .table {
//   max-height: 400px; /* Set a max height for the table */
//   overflow-y: auto; /* Enable vertical scrolling */
// }

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0px !important;
  line-height: normal;
}

.subtitle {
  font-size: 16px;
  color: #999;
  margin-bottom: 40px;
}

.table {
  .ant-table {
    border-radius: 10px;
  }

  .ant-table-thead > tr > th {
    background-color: #f9f9f9;
    font-weight: 600;
  }

  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }

  .ant-table-expand-icon-cell {
    padding-left: 30px;
  }
}

.expandedContent {
  display: flex;
  justify-content: space-between;
  // justify-content: flex-start;
  // width: 1000px;
  // width: 100%;

  .section {
    flex: 1;
    text-align: left;

    strong {
      display: block;
      margin-bottom: 10px;
      color: #333;
    }

    p {
      margin: 0;
      color: #666;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.addPersona {
  color: #6c63ff;
  font-weight: 500;
  text-decoration: underline;
}

.continueButton {
  background-color: #6c63ff;
  border: none;
  font-weight: 600;
  padding: 0 40px;
  height: 40px;
  border-radius: 20px;

  &:hover {
    background-color: #5a54e8;
  }
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.modalContent {
  .ant-modal {
    .ant-modal-content {
      background-color: rgb(249, 250, 251);
    }
  }
}

.modalHeader {
  display: flex;
  align-items:center;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin: 10px 0px;
  h2 {
    margin: 0;
  }
}

.modalFooter {
  margin: 20px 0px 10px 0px;
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;

    margin: 0;
    padding: 0;
  }
  p {
    color: #afafaf;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
}

.popoverContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0;
  }
  span {
    cursor: pointer;
    font-weight: 600;
    color: #7043ff;
  }
}
