.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* centers horizontally */
  align-items: flex-start; /* centers vertically */
  width: 80%;
  height: 100%;
  max-height: 80% !important;
  //   overflow: auto;
  gap: 15px;
}

.table {
  :global(.ant-table.ant-table-small .ant-table-tbody > tr > td) {
    background-color: white;
  }
  :global(.ant-table-thead > tr > th) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }
  :global(.ant-table-wrapper .ant-table-thead > tr > td) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }
  :global(.ant-table-thead > tr > td) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }
  :global(tr.ant-table-expanded-row > td) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }
}

.people {
  display: flex;

  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  p {
    margin: 0;
    font-weight: 600;
  }
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.65;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  //   justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 20px 10px;
  border-radius: 8px;
  gap: 10px;

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
}

.headerSelect {
  :global(.ant-select-selector) {
    border: none !important;
    background-color: #f3f4f6 !important;
    color: black !important;
  }
}

.startSequence {
  img {
    width: 30px;
    height: 30px;
  }
}
