.socialMediaContainer {
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-bottom: 5px;

      p {
        margin: 0;
      }

      img {
        margin-right: 5px;
        height: 22px;
      }
    }

    .addAccount {
      color: #7043ff;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.tooltipContent {
  display: flex;
  flex-wrap: wrap;
}

.select_items_tooltip {
  min-width: 100px;
  padding: 6px 12px;
  background: #f2f0ff;
  border-radius: 24px;
  text-align: center;
  margin: 4px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  .select_items__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    color: #4722bc;
    text-overflow: clip;
  }
}

.select_items__remove {
  position: absolute;
  top: 3px;
  right: 10px;
  display: none;
  cursor: pointer;
}