.container {
  position: relative;
  //   width: 65%;
  width: 100%;
  height: 67%;
  min-height: 350px;
  margin: 20px auto;
  perspective: 1000px;
  display: flex;
  flex-direction: column;
}

.ellipticalShadow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //   width: 90vw;
  //   height: 90vh;
  width: 100%;
  height: 100%;
  // background: radial-gradient(ellipse at center, #8b67fd 0%, rgba(190, 150, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
  filter: blur(10px);
  z-index: -10;
}

.cardWrapper {
  position: relative;
  flex-grow: 1;
  height: calc(100% - 100px); // Leave space for buttons
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  z-index: 2220; // Ensure buttons are above cards
  height: 82px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  background-color: #7043ff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button:not(:disabled):hover {
  background-color: #5c35d6;
}
