.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  //   background-color: #f9f9f9;
  //   border: 1px solid #d9d9d9;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

input {
  width: 100%;
}

.button {
  margin-left: 8px;
}
.saved-filter-item {
  background-color: #fff;
  border-radius: 13px;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    // background: #faf9ff;
    // border: 1px solid var(--primary-800, var(--color-blue-08));
    // border-block-end: 1px solid var(--primary-800, var(--color-blue-08)) !important;
    background: linear-gradient(#faf9ff, #faf9ff) padding-box, linear-gradient(to right, #7144ff, #b649f3) border-box;
    border: 1px solid transparent;
    border-block-end: 1px solid transparent !important;
  }
}
.selected-item {
  background: linear-gradient(#faf9ff, #faf9ff) padding-box, linear-gradient(to right, #7144ff, #b649f3) border-box;
  border: 1px solid transparent;
  border-block-end: 1px solid transparent !important;
}
.tags {
  color: #7043ff !important;
  background-color: #f2f0ff !important;
}
.save-filters-icon {
  margin-right: 8px;
  color: #8c8c8c;
}
.saved-filters-list {
  padding: 0;
}
.redTags {
  color: #ff5c5c !important;
  background-color: #ff5c5c1a !important;
  border-color: #f24e4e1a;
}
.filterTitle {
  color: var(--Nutrals-300, #5e6c84);
  width: 100%;
  /* Title/Title small */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.018px;
}

.advancedFilterTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: var(--Nutrals-300, #5e6c84);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.018px;
  img {
    // cursor: pointer;
  }
}

.innerOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  p {
    font-weight: 600;
    margin: 0;
  }
}
.okButton {
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--primary-600-main, #7043ff);
  color: var(--Nutrals-1000, var(--Color, #fff));

  /* Label/Label large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}
.cancelButton {
  border-radius: 16px;
  border: 1px solid var(--Nutrals-600, #dfe1e6);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-600-main, #7043ff);

  /* Label/Label large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
}
