.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .select {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      color: #051123;
      margin-bottom: 5px;
    }
  }
}

.messageContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    p {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      color: #051123;
      margin-bottom: 5px;
    }
    span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      color: #7043ff;
      margin-top: 5px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}

.mentions {
  position: relative;
  .mentionsSettings {
    border-top: 1px solid #e6e6e6;
    padding-top: 10px;
    // padding-left: 15px;
    gap: 20px;
    position: absolute;
    bottom: 58px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 96%;
    margin-left: 2%;
    height: 85px;
    // padding: 0px 20px;
    .left,
    .right {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #7043ff;
      font-weight: 600;
      font-size: 12px;
      padding-left: 8px;
      img {
        margin-right: 5px;
        height: 18px;
        width: 18px;
      }
    }
    .left:hover,
    .right:hover {
      opacity: 0.75;
    }
  }
}

.platformTag {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  color: #5e6c84;
  font-size: 13px;

  img {
    padding: 2px 0px;
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
}

.templates {
  p {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 600;
    color: #051123;
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 83px;
}

.flexDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0;
    font-size: 14px;
  }
  img {
    margin-right: 5px;
  }
}

.magicChar {
  position: relative;
  display: inline-block;
  animation: sparkle 0.5s ease-in-out forwards;
  opacity: 0;
}

@keyframes sparkle {
  0% {
    opacity: 0;
    transform: scale(0.8);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3);
  }
}

.bookmarksTitle {
  display: flex;
  align-items: center;
  height: 32px;
}

.popoverBody {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
