.otpContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.otpInput {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid #ccc;
  border-radius: 12px;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: #7043ff;
  }

  &:disabled {
    background-color: #f1f1f1;
    border-color: #ddd;
  }
}
