.trialBanner {
  background-image: url('../../Assets/svg/trial_banner.svg');
  background-size: cover;
  background-position: right center;
  background-color: #091e42;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
}

.trialText {
  font-family: 'Roboto';
  font-size: 16px;
  margin-left: 10px;
}

.trialButtonBuy {
  background-color: #ef5da8;
  font-family: 'Roboto';
  font-weight: 600;
  margin-left: 20px;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.trialButtonBuy:hover {
  background-color: #ed6caf;
}

.trialButtonExtension {
  background-color: #7043ff;
  font-family: 'Roboto';
  font-weight: 600;
  margin-left: 20px;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.trialButtonExtension:hover {
  background-color: #7c55fb;
}
