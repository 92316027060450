.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 25px;
}

.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .newRespondBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #7043ff;
    cursor: pointer;
    font-weight: 600;
  }

  .newRespondBtn:hover {
    opacity: 0.8;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }
}

.searchFilterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.panelHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-weight: 600;
  }
  .circle {
    border-radius: 50%;
    background-color: #97a0af;
    color: white;
    font-weight: 600;
    font-size: 11px;
    padding: 0px;
    margin: 0px;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    span {
      width: 100%;
    }
  }
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;

  overflow-y: auto;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden; /* Hide overflow content */
  padding: 6px 12px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    overflow: hidden;
    p {
      font-weight: 600;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      img {
        height: 20px;
        width: 20px;
        margin-left: 5px;
        cursor: pointer;
      }
      img:hover {
        opacity: 0.8;
      }
      .messageType {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #97a0af;
      }
    }
  }
  .message {
    flex: 1;
    overflow: hidden; /* Hide overflow initially */
    text-overflow: ellipsis;
    white-space: pre-wrap; /* Respect newline characters and allow wrapping */
    word-wrap: break-word; /* Break words that are too long */
    width: 100%;
    max-height: 25px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    p {
      margin: 0;
    }

    &:hover {
      max-height: fit-content;
      overflow: visible; /* Show full content on hover */
      text-overflow: clip; /* Remove ellipsis */
      white-space: pre-wrap; /* Keep respecting newline characters */
    }
  }
}

.card:hover .message {
  max-height: fit-content;
  overflow: visible; /* Show full content on hover */
  text-overflow: clip; /* Remove ellipsis */
  white-space: pre-wrap; /* Keep respecting newline characters */
}

.configuration {
  padding: 0px 24px;
  .slider {
    position: relative;
    width: 100%; /* Adjust as needed */
  }
  .floating {
    position: absolute;
    color: #7043ff;
    padding: 5px;
    border-radius: 3px;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
  }
}

.recommended {
  position: absolute;
  // background-color: #ffcc00; /* Highlight color for 'recommended' */
  color: rgb(200, 200, 200);
  padding: 5px;
  font-size: 10px;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 2;
}

.inputNumber {
  position: relative;
  display: flex;
  flex-direction: column;
  .minutes {
    position: absolute;
    left: 40px;
    top: 4px;
  }
}

.collapse {
  max-height: fit-content;
  overflow-y: auto;
  width: calc(100% + 10px);
  overflow-y: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 2px;
  }
}

.flexDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin: 0;
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
}
