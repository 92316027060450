.page {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: url('../../../assets/png/SignUp.png') 50% / cover no-repeat #f9fafb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  padding-bottom: env(safe-area-inset-bottom);
}

.innerPage {
  width: 100%;
  height: 100%;
  padding: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.innerPageMobile {
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.leftMobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  gap: 15px;
}
.left {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  gap: 48px;
}

.right {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-self: stretch;
}

.rightImage {
  margin-bottom: 20px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 9.723px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.content {
  display: flex;
  width: 540px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 12px 24px;
  height: 100%;
  width: 100%;
  flex: 1;
  .header {
    // min-height: 100px;
  }

  .mainBody {
    height: 100%;
  }
  .mainBodyWeb {
    // height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .rightMain {
    }

    .leftMain,
    .rightMain {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 50%;
    }

    .leftMain {
      // gap: 20px;
      // flex-direction: column;
      // align-items: center;
      // text-align: center;
    }

    .rightMain {
      padding-right: 150px;
    }
  }
}

.outerContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  flex: 1;

  .mainBody {
    width: 100%;
    height: 100%;
  }
  .mainBodyWeb {
    // height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .rightMain {
    }

    .leftMain,
    .rightMain {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 50%;
    }

    .leftMain {
      // gap: 20px;
      // flex-direction: column;
      // align-items: center;
      // text-align: center;
    }

    .rightMain {
      padding-right: 150px;
    }
  }
}
