.modal {
  display: flex;
  min-width: 1050px !important;
  overflow: auto !important;
  position: absolute;
}

.modal_outreach_connection {
  display: flex;
  // min-width: 520px !important;
  // width: unset !important;
  width: 1050px !important;

  overflow: auto !important;
}
.ant-modal .ant-modal-content {
  min-width: fit-content !important;
}

.ant-modal-content {
  min-width: fit-content !important;
}
