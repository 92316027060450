.customTagStatus {
  display: flex;
  align-items: center;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #ebecf0;
  border-radius: 20px;
  color: #3f4254;
}

.statusDot {
  height: 5px;
  width: 5px;
  background-color: #65c6f2;
  border-radius: 50%;
  margin-right: 5px;
}

.statusText {
  display: inline-block;
  min-width: 55px;
  text-align: center;
}
