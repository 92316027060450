.details {
  padding: 4px;
  max-width: 480px;
  // user-select: none;

  h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 0px;
  }

  span {
    color: #5e6c84;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
  }

  p {
    color: #97a0af;
    margin: 4px 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .tags {
    display: flex;
    gap: 8px;
    margin: 8px 0;
    justify-content: flex-start;

    .tag {
      background-color: #f2f0ff;
      color: #7043ff;
      padding: 4px 12px 4px 12px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.004em;
      width: 100px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .expanded {
    margin-top: 20px;
    border-top: 1px solid #dfe1e6;
    .riskScore {
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #dfe1e6;

      p {
        display: flex;
        align-items: center;

        color: #091e42;
        font-weight: bold;
        margin-bottom: 4px;
        font-size: 14px;
      }

      .level {
        filter: blur(3.5px);
        display: flex;
        align-items: center;

        span {
          padding: 0px 20px;
          color: #5e6c84;
        }

        .premium {
          filter: blur(3.5px);
        }
      }
    }

    .companyDetails {
      margin-top: 16px;

      p {
        color: #091e42;
        font-weight: bold;
        margin-bottom: 4px;
        font-size: 14px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          margin-bottom: 4px;
          color: #051123;
          span {
            min-width: 180px;
            color: #97a0af;
          }
        }
      }
    }
  }
  .seeMoreLess {
    cursor: pointer;
    color: #005af4 !important;
    text-decoration: underline;
  }
}
