.container {
  .search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    input {
      background: url(../../../../assets/svg/leads_search.svg) #ffffff no-repeat;
      background-position: left 20px bottom 10px;
      padding-left: 50px;
      border: 1px solid transparent;
      border-radius: 10px;
    }
    Button {
      margin-left: 15px;
      border: 1px solid #dfe1e6;
      border-radius: 24px;
      height: 40px;
    }
    .input_box {
      background: linear-gradient(to top, #b649f3, #7144ff);
      background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
      padding: 1px;
      border-radius: 10px;
      width: 100%;
    }
  }
  .matchesLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .importLeads {
      display: flex;
      flex-direction: row;
      height: 100%;
      gap: 20px;
      align-items: center;
      justify-content: center;
      color: #7043ff;
      font-weight: 600;

      img {
        &:hover {
          //   background-color: rgb(250, 250, 250);
          opacity: 0.8;
        }
      }
    }
  }
}

.contactName {
  display: flex;
  flex-direction: column;
}
.searchingLeads {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    margin: 15px 0;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e6c84;
    margin-bottom: 15px;
  }
  h2 {
    font-weight: 500;
    font-size: 19px;
    line-height: 32px;
    text-align: center;
    color: #091e42;
    margin: 0 0 15px;
  }
}

.LeadsModalAndFilters__LeftSection {
  display: flex;
  align-items: center;

  .LeadsModalAndFilters__Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #091e42;
  }
}
.LeadsModalAndFilters__TopSection .matchesLine {
  padding: 30px 25px 20px;
  border-bottom: 1px solid #dfe1e6;
  margin-bottom: 0;
}
.LeadsModalAndFilters__TopSection .search {
  padding: 25px;
  margin-bottom: 0;
}
.LeadsModalAndFilters__Content_Section {
  display: flex;
  flex-direction: row;
  height: 100%;
  border-top: 1px solid rgb(235, 236, 240);
  .Content_Right {
    flex-direction: column;
    display: flex;
    height: 100%;
    padding: 25px;
    border-left: 1px solid #dfe1e6;
    width: 423px;
    max-height: 650px;
    overflow: auto;
    .LeadsModalAndFilters__Title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0015em;
      color: #051123;
    }
    .matchesLine {
      border-bottom: 1px solid #dfe1e6;
      padding-bottom: 16px;
      margin-bottom: 0;
    }
  }
  .Content_Left {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px;
    width: calc(100% - 423px);
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      margin-bottom: 15px;
      width: 100%;
      .right__content {
        display: flex;
        align-items: center;
      }
      .top__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0025em;
        color: #97a0af;
      }
      Button {
        background-color: transparent;
        color: white;
        font-weight: 600;
      }

      Button:hover {
        color: white !important;
        background-color: transparent;
      }
    }
  }
}

.chooseImportMethod {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 20px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // width: 480px;
  Button {
    width: 230px;
    min-height: 32px;
  }
  .permissionButton {
    width: 230px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.actions {
  display: flex;
  line-height: 2.5;
  align-items: center;

  img {
    margin-right: 10px;
    height: 18px;
    width: 18px;
    justify-content: space-between;
  }
}

.crown {
  z-index: 9;
  height: 20px;
  margin-left: 5px;
}

.companyDiv {
  display: flex;
  flex-direction: column;
  .logos {
    display: flex;
    align-items: center;
    img {
      height: 15px;
      cursor: pointer;
    }
  }
}

.actionsButton {
  // background-color: red;
  cursor: pointer;
  background-color: white !important;
  padding: 5px 12px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border: 1px solid #7043ff;
  color: #7043ff;
}

.actionsButton:hover {
  opacity: 0.7;
}

.disabledActionsButton {
  cursor: not-allowed;
  border: 1px solid lightgray;
  color: lightgray;
}

.loaderContainer {
  position: relative;
  margin-top: 99px;
  img {
    position: absolute;
    top: 7px;
    left: 7px;
    background-color: transparent;
  }
  .loader {
    --border-width: 8px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    --mask: radial-gradient(
      farthest-side,
      transparent calc(100% - var(--border-width) - 0.5px),
      #000 calc(100% - var(--border-width) + 0.5px)
    );
    -webkit-mask: var(--mask);
    mask: var(--mask);

    background: linear-gradient(to top, #7144ff, #b649f3) 100% 0/50% 100% no-repeat,
      linear-gradient(#b649f3 50%, transparent 95%) 0 0/50% 100% no-repeat;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.carousel {
  margin-bottom: 100px;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e6c84;
    margin-bottom: 15px;
  }
}

.allMethods {
  margin-top: 10px;
  cursor: pointer;
  color: #7043ff;
}
