.container {
  .input {
    margin-bottom: 10px;
  }
  .nodes {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
  }
}
