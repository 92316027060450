.node {
  height: 60px;
  width: 100%;
  border: 1px solid #dfe1e6;
  padding: 10px;
  border-radius: 12px;
  background: white;
  display: flex;
  cursor: grab;
  justify-content: space-between;
  position: relative;
  .leftSide {
    display: flex;
    align-items: center;
    img {
      height: 40px;
      margin-right: 10px;
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }
    span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      letter-spacing: 0.001em;
      text-align: center;
      color: #97a0af;
    }
  }

  .rightSide {
    display: flex;
    align-items: center;
    min-width: fit-content;

    .allPlatforms {
      padding: 4px 12px;
      border-radius: 8px;
      background: #19bcfe26;
      color: #19bcfe;
    }
    .channels {
      display: flex;
      line-height: normal;
      gap: 5px;
      img {
        height: 18px;
      }
    }

    p {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }
    span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      letter-spacing: 0.001em;
      text-align: center;
      color: #97a0af;
    }
  }
  &.selected {
    outline: 1px solid #7043ff;
  }
}

.node:hover {
  background-color: #f9f7ff !important;
}

.subText {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  img {
    height: 15px !important;
    width: 15px !important;
  }
  span {
    margin-right: 3px;
  }
}

.timePicker {
  position: absolute;
  top: 5px;
  left: 260px;
}

.textSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stats {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  bottom: -15px;
  display: flex;
  justify-content: space-around;
  gap: 2px;
  span {
    background-color: white !important;
    outline: 1px solid #7043ff;
    color: #7043ff;
    padding: 1px 6px;
    border-radius: 20px;
    font-size: 10px;
    box-shadow: 0px 0px 5px #7043ff;
    text-align: center;
    min-width: 85px;
    align-items: center;
  }
}

.disabled {
  filter: grayscale(100%);
  opacity: 0.5;
}

.disable {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: not-allowed;
  border: 1px solid #dfe1e6 !important; // Removes unique border
  background-clip: unset !important; // Removes unique border
}

.aiResponder {
  // border: 1px solid red;
  //
  // border: 1px solid;

  // border-image-source: linear-gradient(180deg, #7144ff 0%, #b649f3 100%);
}

.aiResponder {
  border: 1px solid transparent;
  background: linear-gradient(to top, white, white), linear-gradient(to bottom, #7144ff, #b649f3);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
.aiResponder:hover {
  border: 1px solid transparent;
  background: linear-gradient(to top, rgb(249, 247, 255, 1), rgb(249, 247, 255, 1)),
    linear-gradient(to bottom, #7144ff, #b649f3);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.flexDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0;
  }
  img {
    width: 15px !important;
    height: 15px !important;
    margin-left: 5px;
  }
}

.disableResponder {
  cursor: not-allowed;
}

.truncatedText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 185px;
  display: inline-block;
}
