.formContainer {
  max-width: 900px;
}

.formTitle {
  margin-bottom: 24px !important;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.rowDiv {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.label {
  flex: 0 0 180px;
  margin: 0 !important;
}

.input {
  flex: 0 0 350px;
}

.bottomSection {
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #f0f0f0;
  gap: 24px;
}

.generateButton {
  width: 250px;
  min-width: 250px;
  height: 40px;
  //   margin-left: 180px;
  margin: 0px;
}

.generatedLinkContainer {
  //   flex: 1;
  //   min-width: 0; // Prevents flex item from overflowing
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.generatedLinkLabel {
  //   margin-bottom: 12px !important;
  margin: 0;
  min-width: fit-content;
}
