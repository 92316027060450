.templateContainer {
  max-height: min(30vh, 220px);
  height: auto;
  box-sizing: border-box;
  overflow-y: scroll;
}

.template {
  flex: 1;
  height: 100%;

  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  .header,
  .body,
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footerBookmark {
    display: flex;
    justify-content: flex-end;
  }
  .header {
    p {
      margin: 0;
    }
    .tags {
      display: flex;
    }
  }
  .body {
  }
  .footer {
    span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: left;
    }
  }
}

.template:hover {
  background-color: rgb(245, 245, 245);
}
.icon {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-weight: 500;
  }
}
.emptyMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 60px;
  img {
    height: 60px;
  }

  p {
    font-weight: 600;
    margin: 8px 0px;
    font-size: 17px;
  }
  span {
    font-size: 13px;
    // color: #5E6C84;
  }
}
