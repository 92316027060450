.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .select {
    display: flex;
    flex-direction: column;
    p {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      color: #051123;
      margin-bottom: 5px;
    }
  }
}

.messageContent {
  .header {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    p {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      color: #051123;
      margin-bottom: 5px;
    }
    span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 600;
      color: #7043ff;
      margin-top: 5px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}

.mentions {
  position: relative;
  .mentionsSettings {
    border-top: 1px solid #e6e6e6;

    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // margin-left: 4%;
    height: 50px;
    padding: 0px 20px;
    .left,
    .right {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #7043ff;
      font-weight: 600;
      font-size: 14px;
      .retweet {
        font-size: 18px;
      }
      img {
        margin-right: 5px;
        height: 18px;
        width: 18px;
      }
    }
    .left:hover,
    .right:hover {
      opacity: 0.75;
    }
  }
}

.platformTag {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  color: #5e6c84;
  font-size: 13px;

  img {
    padding: 2px 0px;
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
}

.templates {
  p {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 600;
    color: #051123;
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

.popoverBody {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

// Media Queries for responsiveness
@media (max-width: 1400px) {
  .mentionsSettings {
    .left,
    .right {
      font-size: 12px !important;

      .retweet {
        font-size: 16px !important;
      }

      img {
        height: 15px !important;
        width: 15px !important;
      }
    }
  }
}

@media (max-width: 1300px) {
  .mentionsSettings {
    .left,
    .right {
      font-size: 11px !important;

      .retweet {
        font-size: 14px !important;
      }

      img {
        height: 12px !important;
        width: 12px !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .mentionsSettings {
    .left,
    .right {
      font-size: 10px !important;

      .retweet {
        font-size: 12px !important;
      }

      img {
        height: 10px !important;
        width: 10px !important;
      }
    }
  }
}
