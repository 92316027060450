.pointsTable {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px 0px 8px 8px;
    background-color: #f9f9f9;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    th,
    td {
      text-align: center;
      padding: 8px 13px;
    }

    th {
      background-color: #7043ff;
      color: #ffffff;
    }

    tr {
      &:hover {
        background-color: #f1f1f1;
      }
    }

    td {
      color: #333;
    }
  }
}
