.userLogged {
  span {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    img {
      padding-top: 0px;
    }
  }
  margin: 19px 19px 19px 8px;
  height: 44px;
}

.name,
.type {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  color: var(--color-white);
}

.name {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  margin: 0px;
}

.type {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  float: left;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userLoggedActive {
  span {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 20%) !important;
    border-radius: 8px;
    background-image: url('../../../assets/svg/arrow_down_white.svg') !important;
    background-position: 200px !important;
    background-repeat: no-repeat !important;

    img {
      padding-top: 0px;
    }
  }
  margin: 19px;
  height: 44px;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
  transition: visibility 0s linear 0.1s;
  width: 140px;
}

.container {
  position: fixed;
  display: flex;
  border-radius: 10px;
}

.container:hover {
  background-color: rgba(255, 255, 255, 20%);
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;

  &:hover {
    background-color: transparent !important;
  }
}
