.outcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  //   font-weight: 600;
  img {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
}
