.import {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 10px;
  //   span {
  //     color: black;
  //     font-weight: 600;
  //   }
}

.popoverContent {
  width: 350px;
  label {
    font-weight: 600;
  }
  .container {
    margin-bottom: 16px;
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 5px;
    width: 20px;
  }
  span {
    font-weight: 600;
  }
}

.myGroups {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  .moreOptions {
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding: 5px 0px;
  }
  .moreOptions:hover {
    //
  }
}

.dropdown {
  display: flex;
  align-items: center;
  // justify-content: flex-start;
  img {
    margin-right: 5px;
  }
}

.collectButton {
  Button {
    margin: 0px;
    height: 30px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.eye {
  a {
    position: relative;
    width: 117px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid #dfe1e6;
    color: #7043ff !important;
    font-size: 12px;
  }

  a::before {
    content: '';
    position: absolute;
    right: 25px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }

  a::after {
    content: '';
    position: absolute;
    left: -50px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg) translate(-50px, 50px);
  }

  a img {
    visibility: visible;
    margin-left: 10px;
  }

  a span {
    visibility: visible;
    width: 100%;
    opacity: 1;
    white-space: nowrap;
    transform: translateX(10px);
  }
}

.modalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p,
  span {
    margin: 0px;
  }
  p {
    font-weight: 600;
  }
  span {
    color: #7043ff;
    cursor: pointer;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  padding: 32px;
  // height: 300px;

  .footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    Button {
      margin: 0;
      width: 165px;
      height: 35px;
      // font-weight: 600;
    }
  }
  .disclaimer {
    cursor: pointer;
    text-decoration: underline;
    color: #4f91ff;
  }

  .disclaimer:hover {
    color: #71a5ff;
  }
}

.flexDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }
  span {
    font-weight: 600;
    // color: #ffc700;
  }
}

.progressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 15px 0px 0px 0px;
    font-weight: 600;
    font-size: 40px;
  }
}

.progress {
  p {
    position: absolute;
    top: -16px;
    // font-weight: 600;
    margin: 0 !important;
    font-size: 10px;
    color: gray;
  }
}

.restricted {
  span {
    font-size: 12px;
    color: gray;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; /* Ensures the final state of the animation is maintained */
}

.fadeOutDown {
  animation-name: fadeOutDown;
  animation-duration: 0.5s;
  animation-fill-mode: forwards; /* Ensures the final state of the animation is maintained */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px); /* Start from 20px down */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at original position */
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0); /* Start at original position */
  }
  100% {
    opacity: 0;
    transform: translateY(15px); /* End 20px down */
  }
}

.popoverContentLinkedin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 6px;
  max-width: 450px;

  img {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    width: 100%;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    Button {
      font-weight: 600;
    }
  }
}
.socialMediaContainer {
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-bottom: 5px;

      p {
        margin: 0;
      }

      img {
        margin-right: 5px;
        height: 22px;
      }
    }

    .addAccount {
      color: #7043ff;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.tooltipContent {
  display: flex;
  flex-wrap: wrap;
}

.select_items_tooltip {
  min-width: 100px;
  padding: 6px 12px;
  background: #f2f0ff;
  border-radius: 24px;
  text-align: center;
  margin: 4px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  .select_items__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    color: #4722bc;
    text-overflow: clip;
  }
}

.select_items__remove {
  position: absolute;
  top: 3px;
  right: 10px;
  display: none;
  cursor: pointer;
}
