.mobileLayout {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  background: url('../../common/assets/png/SignUp.png') 50% / cover no-repeat #f9fafb;
  overflow: hidden;
  h1 {
    margin: 0;
    text-align: center;
    letter-spacing: 0.037em;
    font-weight: 600;
    font-size: 27px;
  }

  span {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.03em;
  }
}
.card {
  width: 90%;
  height: 45%;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  span {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.03em;
  }
}
