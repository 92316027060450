.tagWrapper {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: rgba(25, 188, 254, 0.15);
  width: fit-content;
  color: #19bcfe;
  width: fit-content;
  cursor: pointer;

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;

    color: #19bcfe;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.048px;
  }

  &__icon {
    cursor: pointer;
  }
}
