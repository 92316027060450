.socialMediaCards {
  display: flex;
  gap: 15px;
  //   padding-right: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-bottom: 15px;
  // margin-bottom: 15px;
  // border-bottom: 1px solid #ebecf0;
  // width: 100%;
  Button {
    margin-left: 15px;
    background-color: #7043ff;
    color: white;
    font-weight: 600;
  }

  Button:hover {
    color: white !important;
    background-color: #8763ff;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #ebecf0;
  // width: 100%;
  Button {
    margin-left: 10px;
    background-color: #7043ff;
    color: white;
    font-weight: 600;
  }

  Button:hover {
    color: white !important;
    background-color: #8763ff;
  }
}
