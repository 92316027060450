.campaignsTable {
  // position: relative;
}

.rightSection {
}

.tableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dummyChannels {
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  img {
    height: 20px;
  }
}

.dropDown {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 10px 0px;
  cursor: pointer;
}

.cooldowns {
  // display: flex;
  align-items: center;
  line-height: 0px;
}

.cooldownTable {
  width: 100%;
  // margin: 20px 0;
  // border-collapse: collapse;
}

.cooldownTable table {
  width: 100%;
  // border: 1px solid #ddd;
}

.cooldownTable th {
  padding: 8px 0px 0px 0px;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
}
.cooldownTable td {
  font-weight: 800;
  // padding: 8px;
  text-align: left;
  // border-bottom: 1px solid #ddd;
}

.cooldownTable th {
  // background-color: #f2f2f2;
}

.cooldownTable tr:hover {
  // background-color: #f1f1f1;
}

.selectionActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  Button {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-color: #dfe1e6;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.002em;
    text-align: left;
    img {
      margin-right: 4px;
    }
  }
}

.outOfSchdule {
  display: flex;
  flex-direction: column;
  color: black;
  .countdown {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
}

.dismiss {
  text-decoration: underline;
  color: #7043ff;
  cursor: pointer;
}

.warningPopoverContent {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .outOfSchdule {
    display: flex;
    gap: 5px;
    p {
      font-weight: 600;
      margin: 0px;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.truncatedText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.actionsColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tags {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.meetingBooked {
  color: #7043ff !important;
  margin: 0px;
}

.adjustColumns {
  Button {
    background-color: white;
    color: #7043ff;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
  Button:hover {
    background-color: white !important;
    color: #7043ff !important;
    opacity: 0.75;
  }
}
