.csvPreview {
  &__title {
    overflow: hidden;
    color: #091e42;
    text-align: justify;
    font-feature-settings: 'salt' on;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    margin-bottom: 8px;
  }

  &__description {
    color: rgba(9, 30, 66, 0.6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    align-self: stretch;
  }

  &__tab {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    margin: 24px 0;

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: #faf9ff;
      padding: 6px 10px;
     flex: 1;
      min-width: 55px;
      border: 1px solid #dfe1e6;

      &__label {
        white-space: nowrap;
        overflow: hidden;
        color: var(--Natural-800, #091e42);
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px; /* 142.857% */
        letter-spacing: 0.028px;

        &--active {
          font-weight: 600;
        }
      }

      &__number {
        white-space: nowrap;
        color: #5e6c84;
        text-align: center;
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 200% */
        letter-spacing: 0.018px;
      }

      &--active {
        border: 1px solid #7144ff;
      }
    }
  }

  &__table {
    &__withPagination {
      max-height: calc(100vh - 370px);
    }
  }
}
