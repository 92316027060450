.container {
  .search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      background: url(../../../../assets/svg/leads_search.svg) #ffffff no-repeat;
      background-position: left 20px bottom 10px;
      padding-left: 50px;
      border: 1px solid transparent;
      border-radius: 10px;
    }

    Button {
      margin-left: 15px;
      border: 1px solid #dfe1e6;
      border-radius: 24px;
      height: 40px;
    }

    .input_box {
      background: linear-gradient(to top, #b649f3, #7144ff);
      background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
      padding: 1px;
      border-radius: 10px;
      width: 100%;
    }
  }

  .matchesLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .importLeads {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #7043ff;
      font-weight: 600;

      img {
        &:hover {
          //   background-color: rgb(250, 250, 250);
          opacity: 0.8;
        }
      }
    }
  }
}

.LeadsModalAndFilters__LeftSection {
  display: flex;
  align-items: center;

  .LeadsModalAndFilters__Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #091e42;
  }
}

.LeadsModalAndFilters__TopSection .matchesLine {
  padding: 30px 25px 20px;
  border-bottom: 1px solid #dfe1e6;
  margin-bottom: 0;
}

.LeadsModalAndFilters__TopSection .search {
  padding: 25px 25px 0px 25px;
  margin-bottom: 0;
}

.LeadsModalAndFilters__Content_Section,
.LeadsModalAndFilters__Content_Section_Table_Paywall {
  // overflow-y: auto;
  max-height: 680px;
  background-color: white;

  .Content_Left {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      margin-bottom: 15px;
      width: 100%;

      Button {
        color: #7043ff !important;
      }

      .right__content {
        display: flex;
        align-items: center;
        // margin-right: 35px;
      }

      .top__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0025em;
        color: #97a0af;
      }

      Button {
        background-color: transparent;
        color: white;
        font-weight: 600;
      }

      Button:hover {
        color: white !important;
        background-color: transparent;
      }
    }
  }
}

.LeadsModalAndFilters__Content_Section_Table_Paywall {
  overflow-y: hidden;
}

.content {
  background-color: white !important;
  // min-width: 1200px;
}

.sider {
  background-color: white !important;

  // max-height: 10px;
}

.Table_paywall {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 130px;
  left: 0px;
  right: 0px;
  bottom: 70px;
  align-items: center;
  justify-content: center;
  // min-height: 610px;
  // background-image: url('../../../../assets/png/leadtablescreen.png');
  /* Update the path as necessary */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  // background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  margin-top: -42px;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-top: 0;
  border-radius: 0px 0px 8px 8px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    margin: 20px 0;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e6c84;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 500;
    font-size: 19px;
    line-height: 32px;
    text-align: center;
    color: #091e42;
    margin: 0 0 15px;
  }
}

.loading {
  background-color: white;
  min-height: 500px;
  display: flex;
  align-items: center;
}

.noLeadsFound {
  background-color: white;
  min-height: 500px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: gray;
}

.hiddenEye {
  cursor: pointer;
  height: 30px;
  width: 30px;
}
