.card {
  border: 1px solid #dfe1e6;
  flex: 1 1 0;

  h3 {
    margin-top: 0px;
    margin-bottom: 22px;
    color: var(--Natural-800, #091e42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.028px;
  }
}

.leadsOverview {
  width: 100%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
}

.progressBar {
  display: flex;
  height: 10px;
  margin-bottom: 20px;
}

.paused {
  background-color: #dfe1e6;
  border-radius: 12px 0px 0px 12px;
  position: relative;
}

// .paused:after {
//   content: '';
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 0 0 5px 5px;
//   border-color: transparent transparent white transparent;
// }

.continuing {
  background-color: rgba(25, 188, 254, 0.5);
  position: relative;
}

.completed {
  background-color: #19bcfe;
  border-radius: 0px 12px 12px 0px;
}

.loadingBar {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    #dfe1e6,
    rgba(25, 188, 254, 0.5),
    #19bcfe,
    rgba(25, 188, 254, 0.5),
    #dfe1e6,
    #dfe1e6,
    rgba(25, 188, 254, 0.5),
    #19bcfe,
    rgba(25, 188, 254, 0.5),
    #dfe1e6,
    #dfe1e6,
    rgba(25, 188, 254, 0.5),
    #19bcfe,
    rgba(25, 188, 254, 0.5),
    #dfe1e6
  );
  animation: loadingAnimation 4s linear infinite;
}

@keyframes loadingAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 0;
  }
}

.labels {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .text {
    .header {
      color: var(--Natural-600, #97a0af);
      font-feature-settings: 'salt' on;
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.count {
  color: var(--Black-700---Secondary, var(--Color-Black-700, #4d4d4d));
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

@media (max-width: 1700px) {
  .hide {
    display: none !important;
  }
}
@media (max-width: 1250px){

  .labels{
    .text {
      .header {
        font-size: 12px;
      }
      }
  }
  //.count {
  //  font-size: 12px;
  //}
}