.controls {
  position: relative;
  width: 120px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 15px;
  img {
    position: relative;
    z-index: 1000 !important;
    cursor: pointer !important;
  }
}
