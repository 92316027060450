.notificationDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 25px;
  text-align: center;
  .flexDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    span {
      font-size: 16px;
    }
  }
  p {
    font-size: 32px;
    margin: 0px;
    font-weight: 600;
    text-align: center;
  }

  img {
    height: 100px;
    width: 100px;
  }

  Button {
    padding: 0px 60px;
    font-weight: 600;
  }
}

.customModal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // padding: 10px;
  gap: 25px;
  text-align: center;

  .header {
    padding: 20px;
    border-bottom: 1px solid #ebecf0;
  }

  .flexDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    span {
      font-size: 16px;
    }
  }

  p {
    font-size: 32px;
    margin: 0px;
    font-weight: 600;
    text-align: center;
  }

  img {
    height: 100px;
    width: 100px;
  }

  Button {
    padding: 0px 60px;
    font-weight: 600;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  // border-bottom: 1px solid red;
  P {
    font-size: 18px;
    margin: 0;
    line-height: 1;
  }
  span {
    color: #7043ff;
    cursor: pointer;
    font-weight: 600;
  }
}

.rowDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  img {
    height: 20px;
    width: 20px;
  }
}

.footer {
  padding-bottom: 30px;
  Button {
    margin: 0;
    height: 35px;
    padding: 0px 40px;
    font-size: 12px;
  }
}
