.commentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 95%;
}

.commentContent {
  flex: 1;
  margin-right: 16px;
  min-width: 0; // Important for text truncation
}

.author {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textWrapper {
  position: relative;
  max-height: calc(1.5em * 2); // Exactly two lines based on line height
  overflow: hidden;
}

.text {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.verificationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  flex-shrink: 0; // Prevent verification icon from shrinking
}

.verificationIcon {
  position: relative;
  width: 24px;
  height: 24px;
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: #00bfa5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
