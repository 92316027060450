.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('/common/assets/svg/premium_task_screen.svg');
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  z-index: 1;
  overflow: hidden !important;
}

.blur-overlay {
  overflow: hidden !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white overlay */
  backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
  z-index: 1; /* Ensures it appears on top of the background image */
}
