.aiComment {
  position: absolute;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: 80%;
  height: 120px;
  bottom: -20px;
  border: 3px solid transparent;
  border-radius: 80px;
  background: linear-gradient(to right, white, white), linear-gradient(to right, #7043ff, #fe50e6);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  z-index: 100000;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sendCommentSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 65px;
  img {
    height: 50px;
    width: 50px;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
  }
}

.inputSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 87%;
  gap: 5px;
  padding-left: 15px;
  margin-top: 14px;

  .actions {
    margin-top: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    .discard {
      padding: 4px 10px;
      color: #777777;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: #979797;
      }
    }

    .improve {
      display: flex;
      align-items: center;
      gap: 3px;
      background-color: #f9f7ff;
      color: #7043ff;
      padding: 4px 10px 4px 4px;
      border-radius: 4px;
      font-weight: 600;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
      &:hover {
        background-color: #f2eeff;
      }
    }
  }

  TextArea {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #f5f5f5;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.suggestionsList {
  position: absolute;
  left: 15%;
  width: 80%;
  margin-top: 8px;
  border-radius: 8px;
  list-style: none;
  padding: 0;
  z-index: 100001;
  /* bottom is now dynamically set inline in the component */
  opacity: 0;

  .bold {
    color: #7043ff;
    font-weight: 600;
  }
}

.suggestionsList li {
  padding: 12px 0;
  cursor: pointer;
  margin: 0;
}

.auroraText {
  position: relative;
  /* Base text color is black */
  color: black;
  font-weight: 700;
  /* Apply a linear gradient that spans from black, through #7043ff, back to black */
  background: linear-gradient(90deg, rgb(212, 212, 212) 0%, #7043ff 50%, rgb(177, 177, 177) 100%);
  /* Increase background size to allow for a smooth transition */
  background-size: 200% auto;
  /* These properties clip the background to the text */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Animate the background-position for a smooth left-to-right sweep */
  animation: auroraAnimation 2s linear infinite;
  display: inline-block;
}

@keyframes auroraAnimation {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
