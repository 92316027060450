.suggestionsList {
  position: absolute;
  left: 15%;
  width: 80%;
  margin-top: 8px;
  border-radius: 8px;
  list-style: none;
  padding: 0;
  z-index: 100001;
  opacity: 0;
}

.bold {
  color: #7043ff;
  font-weight: 600;
}

.suggestionsList li {
  padding: 12px 0;
  cursor: pointer;
  margin: 0;
}
