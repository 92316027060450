.categorizer-page {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #f9fafb;
    &__header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
        border-bottom: 1px solid #dfe1e6;
        padding: 16px 24px;
        background: #fff;
        &__section {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
        &__setting {
            color: #091e42;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            letter-spacing: 0.048px;
            cursor: pointer;
        }

        &__pageName {
            color: #091e42;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            text-transform: capitalize;
        }
    }

    &__body {
        padding: 24px;
        background: #f9fafb;
        &__content {
            display: flex;
            width: 100%;

            flex-wrap: wrap;
            gap: 16px;
        }
        &__section {
            margin-bottom: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .wrapping {
                display: flex;
                gap: 25px;
            }

            &__title {
                color: white;
                color: #091e42;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.036px;
                width: 160px;
            }

            &__right {
                display: flex;
                align-items: center;
                min-width: 0;

            }
        }

        .c-filter {
            margin: 0 10px;
            min-width: 0;
        }
    }
}

.emptyList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 24px;

    &__title {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 8px;
    }

    &__description {
        max-width: 440px;
        color: #97a0af;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 171.429% */
        letter-spacing: 0.07px;
    }

    &__button {
        display: flex;
        height: 40px;
        padding: 4px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        background: #7043ff;
        color: white;

        &:hover {
            color: white !important;
        }
    }
}