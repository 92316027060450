.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.containerLarge {
  min-width: 230px;
}

.containerSmall {
  min-width: 120px;
}

.progress {
  display: flex;
  overflow: hidden;
  height: 10px;
  border-radius: 50px;
  background-color: #ecf0f1;
}

.progressLarge {
  width: 190px;
}

.progressSmall {
  // width: 90px;
  width: 140px;
}

.progressXL {
  width: 100%;
}

.completed {
  background-color: #8f6bff;
}

.pause {
  background-color: #97a0af !important;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent 0.45rem,
    transparent 0.8rem,
    rgba(255, 255, 255, 0.3) 0.7rem,
    rgba(255, 255, 255, 0.3) 1rem
  );
}

.error {
  background-color: transparent !important;
}

.progress-bar {
  overflow: hidden;
  text-align: center;
  border-radius: inherit;
  background-color: #8f6bff;
  position: relative;
  &.progressBarAnimated::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: repeating-linear-gradient(
      -45deg,
      transparent 0.45rem,
      transparent 0.8rem,
      rgba(255, 255, 255, 0.3) 0.7rem,
      rgba(255, 255, 255, 0.3) 1rem
    );
    background-size: 200% 200%;
    animation: barberpole 3s linear infinite;
  }
}

.text {
  color: var(--Natural-600, #97a0af);
  // font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.errorText {
  color: red;
}

// @keyframes progress-bar-shine {
//   to {
//     transform: translateX(0);
//     opacity: 0.1;
//   }
// }
// @keyframes barberpole {
//   100% {
//     background-position: 100% 100%;
//   }
// }

@keyframes barberpole {
  from {
    background-position: 100%;
  }
  to {
    background-position: 0%;
  }
}
