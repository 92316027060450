.card {
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Color, #fff);
  height: 100%;
  width: 100%;
  max-width: 540px;
  /* Card dropshadow */
  box-shadow: 0px 7.293px 21.878px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  z-index: 10;
  // &::-webkit-scrollbar {
  //   width: 0px !important;
  // }
}
.cardMobile {
  display: flex;
  padding: 44px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  // align-self: stretch;
  border-radius: 10px;
  // background: var(--Color, #fff);
  height: 100%;
  width: 100%;
  /* Card dropshadow */
  // box-shadow: 0px 7.293px 21.878px 0px rgba(0, 0, 0, 0.05);
  // margin-top: -15px;
  z-index: 10;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.step {
  color: #97a0af;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.07px;
  align-self: stretch;
}
.lets {
  color: var(--Natural-900, #051123);
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
}
.inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.input_frame {
  display: flex;
  height: 72px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.input_text {
  color: var(--Natural-800, #091e42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.07px;
  height: 24px;
  flex-shrink: 0;
  align-self: stretch;
}
.input {
  display: flex;
  height: 40px;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  Input {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(223, 225, 230, 0.6);
    background: var(--Color, #fff);
  }
}
.addon {
  display: flex;
  align-items: center;

  .text {
    color: #97a0af;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.014px;
  }
}
.button {
  display: flex;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  Button {
    height: 40px;
    padding: 0px 16px;
  }
}
.button_back {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  Button {
    height: 40px;
    padding: 0px 16px;
    .back_text {
      color: var(--primary-600-main, #7043ff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.028px;
    }
  }
}
.button_back_special {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  Button {
    height: 40px;
    padding: 0px 16px;
    .back_text {
      color: var(--primary-600-main, #7043ff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.028px;
    }
  }
}
.skip_frame {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}
.skip_frame_mobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.skip_frame_3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  margin-top: 40px;
}
.skip {
  color: var(--Natural-700, #5e6c84);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.028px;
  cursor: pointer;
}
